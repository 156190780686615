/**
 * 金钱格式化
 * @param str
 * @param splitter 分割符
 */
export function formatMoney(str: string = "", splitter = ",") {
    return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, splitter);
}

/**
 * 拼接数据
 * @param arr
 * @param splitter 拼接符
 */
export function spliceData(arr: any[], splitter = "、") {
    return arr.filter((x) => x).join(splitter);
}

/** 处理有单位的数据 */
export function getDataWithUnit(data, unit = "") {
    if (data === undefined) return "";
    return `${data} ${unit}`;
}

export function getHasText(d) {
    return d === true ? "有" : d === false ? "无" : "";
}
export function getYesText(d) {
    return d === true ? "是" : d === false ? "否" : "";
}
export function getYesData() {
    return [
        { tagName: "是", tagValue: true },
        { tagName: "否", tagValue: false },
    ];
}

/** 解析查询参数 */
export function parseQueryStr(url, attr?: string) {
    const arr = url.split("?"),
        res: any = {};
    if (!arr[1]) {
        return res;
    }
    const tempArr = arr[1].split("#")[0].split("&");
    tempArr.forEach(function (item) {
        const query = item.split("=");
        res[decodeURIComponent(query[0])] = decodeURIComponent(query[1]);
    });
    if (attr) return res[attr];
    return res;
}

export function strQueryParams(data, isPrefix = false) {
    let prefix = isPrefix ? "?" : "";
    let _result: any[] = [];
    // tslint:disable-next-line:forin
    // eslint-disable-next-line guard-for-in
    for (let key in data) {
        let value = data[key];
        // 去掉为空的参数
        if (["", undefined, null].includes(value)) {
            continue;
        }
        if (value.constructor === Array) {
            value.forEach((_value) => {
                _result.push(encodeURIComponent(key) + "[]=" + encodeURIComponent(_value));
            });
        } else {
            _result.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
        }
    }
    return _result.length ? prefix + _result.join("&") : "";
}

export function getTreeSelectData(d, parentId = "0") {
    const result = d.filter((x) => x.parentId === parentId).map((x) => ({ ...x, value: x.tagValue, label: x.tagName }));
    result.forEach((res) => {
        const children = getTreeSelectData(d, res.id);
        if (children.length > 0) res.children = children;
    });
    return result;
}

/** 给列表数据加key */
export function giveTableKey(d) {
    return d.map((x, i) => ({ ...x, key: i + 1 }));
}

/** 为字符串插入字符 其中soure为原字符串,start为将要插入字符的位置，newStr为要插入的字符 */
export function insertStr(soure = "", start, newStr) {
    return soure.slice(0, start) + newStr + soure.slice(start);
}

export function removeHTMLStr(s = "") {
    return s.replace(/<[^>]+>/g, "");
}
// export function parseUserName(s: string = "") {}
