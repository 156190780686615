import { HttpService, resolveService } from "@reco-m/core";

export class UserProfileHttpService extends HttpService {
    constructor() {
        super("Authorization/UserProfile");
    }

    uploadAvatar(data) {
        return this.httpUpload(this.resolveUrl("UploadAvatar"), data);
    }

    getHeadImage() {
        return this.httpGet("Get");
    }

    postUserProfile(data: any) {
        return this.httpPost("Post", data);
    }

    // 上传头像
    uploadPortrait(data) {
        return this.httpPost("UploadAvatar", data);
    }

    postTake(data: any) {
        return this.httpPost("Post", data);
    }

    canclePostTake(data: any) {
        return this.httpPost("CancelPost", data);
    }
}

export const userProfileService = resolveService(UserProfileHttpService);


