const _client = {
    title: "生物医药产业服务平台",
    techSupport: "技术支持:  上海瑞谷拜特软件技术有限公司",
    urlArgs: "v=180427",
    tel: "021-20231080",
    /** 是否在Header里调用查询工单的接口 */
    isgetOrderOnHeader: true,
    parkCode: "ZCKJ20170707135129",
    ...window["client"],
};

let envUrl = location.host.includes("swyy.spbia.cn") ? "https://swyy.spbia.cn/mobileapi/" : "https://fat.bitechdevelop.com/spbiapark-mobileapi/";
// envUrl = "https://swyy.spbia.cn/mobileapi/";
// https://linux.bitechdevelop.com/spbiapark.mobileapi/

const _server = {
    apiKey: {
        apiKey: "Bitech\\PC",
        secret: "44678314ba0efa0c",
    },
    apiVersion: 9,
    auth: {
        oauth2Url: "authorization/oauth2",
        autoLogin: !0,
        autoRefreshToken: !0,
        anonymousLogin: !0,
    },
    appdownURL: "http://dev.bitechdevelop.com/reco.ipark.appdown/", // app下载地址

    jsonURL: "http://dev.bitechdevelop.com/reco.ipark.cms/webSeo.json", // seo生成json文件路径
    assetsUrl: envUrl, // 图片存放地址，用于富文本中获取图片
    // url: "https://swyy.spbia.cn/mobileapi/",
    // url: "http://192.168.30.237:8080/", // 张德恩
    // url: "http://192.168.30.206:8082/", // 伍振飞
    url: envUrl,
    ...window["server"],
};

const $window = window as any;

export const client: RECO.Mobile.Config.Client = (window["client"] = $window.replaceClient ? $window.replaceClient(_client) : _client);

export const server: RECO.Mobile.Config.Server = (window["server"] = $window.replaceServer ? $window.replaceServer(_server) : _server);
