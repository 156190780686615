export function getDate(date: string | Date, def?: Date): Date | undefined {
    return date instanceof Date
        ? date
        : date
        ? new Date(
              date
                  .replace(/\-/g, "/")
                  .replace(/T/g, " ")
                  .substr(0, 19)
          )
        : def;
}

export function formatNow(fmt: string = "yyyy-MM-dd") {
    return formatDate(new Date(), fmt);
}

export function formatDateTime(date: Date | string, fmt: string = "yyyy-MM-dd hh:mm:ss") {
    return formatDate(date, fmt);
}
// 只用于发送数据格式化数据
export function formatDateTimeSend(date: Date | string, fmt: string = "yyyy-MM-ddThh:mm:ss") {
    return formatDate(date, fmt);
}
export function formatDate(date: Date | string, fmt: string = "yyyy-MM-dd") {
    if (date instanceof Date) return date.format(fmt);
    else if (date) {
        try {
            return getDate(date)!.format(fmt);
            // tslint:disable-next-line:no-empty
        } catch {}
    }
    return date;
}
/**
 * 获取当前日期N天后日期
 * @param days GetDateStr
 */
export function getDateStr(days?: any, fmt: string = "yyyy-MM-dd") {
    let dd = new Date();
    dd.setDate(dd.getDate() + days); // 获取n天后的日期
    return formatDate(dd, fmt);
}

/**
 * 获取指定日期N天后日期
 * @param days GetDateStr
 */
export function getSetDateStr(date: string, days: number, fmt: string = "yyyy-MM-dd") {
    let dd = new Date(date);
    dd.setDate(dd.getDate() + days); // 获取n天后的日期
    return formatDate(dd, fmt);
}

export function formatFormalDate(date: Date | string, fmt: string = "yyyy-MM-dd hh:mm:ss") {
    if (date instanceof Date) return date.format(fmt);
    else if (date) {
        try {
            return getDate(date)!
                .format(fmt)
                .split(".")[0];
            // tslint:disable-next-line:no-empty
        } catch {}
    }

    return date;
}

export function formatDates(fmt: string = "yyyy-MM-dd") {
    return (date: Date) => formatDate(date, fmt);
}

export function formatDateYtom(date: string) {
    let fmt = "yyyy-MM-dd hh:mm";
    return this.formatDate(date, fmt);
}

export function parseStandard(time: string, format: string = "yyyy-MM-dd hh:mm:ss") {
    let t = new Date(time);
    let tf = function(i) {
        return (i < 10 ? "0" : "") + i;
    };
    return format.replace(/yyyy|MM|dd|hh|mm|ss/g, a => {
        switch (a) {
            case "yyyy":
                return tf(t.getFullYear());

            case "MM":
                return tf(t.getMonth() + 1);

            case "mm":
                return tf(t.getMinutes());

            case "dd":
                return tf(t.getDate());

            case "hh":
                return tf(t.getHours());

            case "ss":
                return tf(t.getSeconds());
            default:
                return "";
        }
    });
}

export function format(this: Date, fmt: string): string {
    const o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    };

    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));

    for (const k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));

    return fmt;
}

(Date as any).prototype.format = format;

export function formatTime(date: Date | string, fmt: string = "hh:mm") {
    if (date instanceof Date) return date.format(fmt);
    else if (date) {
        try {
            return getDate(date)!.format(fmt);
            // tslint:disable-next-line:no-empty
        } catch {}
    }

    return date;
}

/**
 * 判断周几
 */
export function getWeekStr(date: string, days: number) {
    let dd = new Date(date);
    dd.setDate(dd.getDate() + days); // 获取n天后的日期
    let week = dd.getDay();
    let str;
    if (week === 0) {
        str = "周日";
    } else if (week === 1) {
        str = "周一";
    } else if (week === 2) {
        str = "周二";
    } else if (week === 3) {
        str = "周三";
    } else if (week === 4) {
        str = "周四";
    } else if (week === 5) {
        str = "周五";
    } else if (week === 6) {
        str = "周六";
    }
    return str;
}
