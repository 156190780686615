import { CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app, changeDocument } from "@reco-w/core-ui";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { cmsService } from "@reco-w/common-service";
import { freeze } from "immer";
import { Namespaces } from "./common";

export namespace cmsCommonModel {
    export const namespace = Namespaces.cmsCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;
    export const reducers: ReducersMapObject = {
        ...CoreReducers,
        *initState() {
            return state;
        },
    };
    export const effects: EffectsMapObject = {
        ...CoreEffects,
        // 获取head标签内的数据
        *getHeadJson({ error, title, params, location }, { call, put }) {
            try {
                const json = yield call(cmsService.getHeadJson);

                let local = location;
                if (location) {
                    local = client["isMultyPark"] ? (getLocalStorage("ParkCode") || client["defaultParkCode"]) + location : location;
                }
                if (json) {
                    changeDocument(json, title, params, local);
                }
            } catch (e) {
                yield call(error, "getHeadJson：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
    };
}

app.model(cmsCommonModel);
