/** 手机号码 */
export const regMobile = /^1[3456789]\d{9}$/;
/** 固定电话 */
export const regTel = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/;
/** 邮箱 */
export const regMail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
/** 身份证正则 */
export const RegIDCard = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
/** 车牌号 */
export const RegCarNumber = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
/** 纯数字 */
export const RegPureNumber = /^\d{1,}$/;

/** 8-20位大小写字母、数字和特殊字符任意三种组合 */
export const regPwd = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[A-Za-z0-9~`!@#$%^&*>,./?<():;'_+={}\-\[\]"]+$/;
// /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/
