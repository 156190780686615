export enum Namespaces {
    memberCommon = "memberCommon",
    cmsCommon = "cmsCommon",
    workorderCommon = "workorderCommon",
}

export enum EnumWorkOrderStatus {
    /** 已取消 */
    Cancel = -5,

    /** 待派单 */
    NoConfim = 0,

    /** 处理中 */
    Processing = 1,

    /** 已退回 */
    Bounced = -1,

    /** 已完成 */
    Completed = 5,

    /** 待评价 */
    NoEvaluate = 6,

    /** 已评价 */
    Evaluate = 7,
}
