import React from "react";
import {Modal} from "antd";
import SwiperCore, {Navigation, Pagination, Zoom} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

SwiperCore.use([Navigation, Pagination, Zoom]);

import {PureComponent, htmlInjectEncode, htmlInjectDecode, getAssetsUrl, transformAssetsUrl} from "@reco-m/core";

export namespace HtmlContent {
    export interface IProps extends PureComponent.IProps {
        html: any;
        component?: any;
        encode?: boolean;
    }

    export interface IState extends PureComponent.IState {
        imgs: any[];
        open: boolean;
        index: number
    }

    export class Component<P extends HtmlContent.IProps = HtmlContent.IProps, S extends HtmlContent.IState = HtmlContent.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            classPrefix: "html-content",
            component: "div",
            html: "",
            encode: true,
        };

        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return {
                imgs: [],
                open: false,
            } as any;
        }

        renderRef(e) {
            const _this = this;
            let imgs = [] as any;
            $(document).keydown((event) => {
                if (event.keyCode === 27) {
                    _this.setState({
                        open: false,
                    });
                }
            });
            $(e)
                .off(".img")
                .on("click.img", "img", function () {
                    _this.setState({
                        open: true,
                        imgs: imgs,
                        index: $(this).attr("id") as any || 0
                    });
                })
                .find("img")
                .each((i, el) => {
                    imgs.push($(el).attr("id", i).attr("src"));
                });
        }

        onCancel() {
            this.setState({open: false});
        }

        componentWillUnmount() {

        }

        render(): React.ReactNode {
            const {html, className, component, encode, ...props} = this.props as any,
                baseUrl = getAssetsUrl();

            delete (props as any).classPrefix;

            let $html = $(`<div>${html}</div>`);

            $html.find("img").each((_index, img) => {
                let $img = $(img);
                let imgSrc = $img.attr("src");
                if (imgSrc && (imgSrc.startsWith("./") || (imgSrc.startsWith("/") && !imgSrc.startsWith("//")))) $img.attr("src", baseUrl + imgSrc.substr(1));
                $(img).attr("style", "max-width: 100%");
            });

            $html.find("a").each((_index, a) => {
                let $a = $(a);
                let aHref = $a.attr("href");
                if (aHref && aHref.startsWith("./")) $a.attr("href", baseUrl + aHref.substr(1));
            });

            const newHtml = $html.html() ? $html.html() : (encode ? htmlInjectEncode : htmlInjectDecode)($html.html());

            return (
                <>
                    {React.createElement(component, {
                        ...props,
                        ref: this.renderRef.bind(this),
                        className: this.classnames(className, this.getClassSet()),
                        dangerouslySetInnerHTML: {__html: newHtml},
                    })}
                    {this.state.open ? (
                        <Modal
                            title={
                                <div>
                                    预览 <span className="size-12 text-error">(温馨提示：双击可放大，拖动滚动条预览大图)</span>
                                </div>
                            }
                            wrapClassName="upload-modal"
                            footer={null}
                            width={"100%"}
                            visible={this.state.open}
                            destroyOnClose={true}
                            onCancel={() => this.onCancel()}
                        >
                            <Swiper initialSlide={this.state.index} zoom={true} keyboard={true} navigation={true}
                                    pagination={true} slidesPerView='auto'>
                                {this.state.imgs &&
                                this.state.imgs.length > 0 &&
                                this.state.imgs.map((e, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div className="reco-upload-item">
                                                <img src={transformAssetsUrl(e)} alt=""/>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </Modal>
                    ) : null}
                </>
            );
        }
    }
}


