import React from "react";

import { PureComponent } from "@reco-m/core";

export namespace Banner {
    export interface IProps extends PureComponent.IProps {
        bimg?: any;
        cimg?: any;
        cimgShow?: boolean;
        title?: string;
    }

    export interface IState extends PureComponent.IState {
        bimg?: string;
        cimg?: string;
        cimgShow?: boolean;
    }

    export class Component<P extends IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            cimgShow: true,
        };

        render(): React.ReactNode {
            const { bimg, cimg, cimgShow, title } = this.props;
            return (
                <div
                    className="banner-box"
                    style={{
                        backgroundImage: `url(${bimg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    {cimgShow === true ? <img src={cimg} alt="" /> : <span>{title || ""}</span>}
                </div>
            );
        }
    }
}
