import { HttpService, resolveService, isAnonymous } from "@reco-m/core";

export class AuthHttpService extends HttpService {
    // currentUser: any;
    protected currentUserPromise: any;
    // protected currentUserEditPromise: Promise<any> | null;

    constructor() {
        super("authorization/oauth2");
    }

    getCurrentUser() {
        if (!isAnonymous()) {
            this.currentUserPromise || (this.currentUserPromise = this.httpGet("current-user"));
        }

        return this.currentUserPromise || Promise.resolve({});
    }

    clearCurrentUser() {
        delete this.currentUserPromise;
    }

    // constructor() {
    //     super("Authorization/Index");
    // }

    // getCurrentUser() {
    //     return this.currentUser ? Promise.resolve(this.currentUser) : this.httpGet("GetCurrentUser").then(d => (this.currentUser = d));
    // }

    // clearCurrentUser() {
    //     delete this.currentUser;
    // }

    refreshCurrentUser() {
        return this.clearCurrentUser(), this.getCurrentUser();
    }

    getEdit(data: any) {
        return this.httpGet(this.resolveUrl("GetEdit", data.id), data);
    }

    /**
     * 修改个人信息
     */
    modifyPersonMessage(data: any, userId: number) {
        return this.httpPut(this.resolveUrl("PutAccount", userId), data);
    }

    /**
     * 获取社会化账号
     */
    getSocialAccount() {
        return this.httpGet("AccountAccess");
    }

    sendBindCode(data: any) {
        return this.httpPut("SendEditMobileCode", data);
    }

    bindMobile(data: any) {
        return this.httpPut("EditMobile", data);
    }

    resetPwd(data: any) {
        return this.httpPut("GuestResetPwd", data);
    }
    authResetPwd(data: any) {
        return this.httpPut("ResetPwd", data);
    }

    logout() {
        this.clearCurrentUser();

        return this.http.logout();
    }
}

export const authService = resolveService(AuthHttpService);


export class AuthAccountHttpService extends HttpService {
    currentUser: any;
    constructor() {
        super("authorization/account");
    }
    put(id, param) {
        return this.httpPut("" + id, param);
    }
    sendEditMobileCode(data) {
        return this.httpPut("send-edit-mobile-code", data)
    }
    editMobile(data) {
        return this.httpPut("edit-mobile", data)
    }
    sendGuestResetPwd(data) {
        return this.httpPut("send-guest-reset-pwd", data)
    }
    guestResetPwd(data) {
        return this.httpPut("guest-reset-pwd", data)
    }
    /**
     * 获取简单用户数据
     */
    getSampleUser(params: object) {
        return this.httpGet("fast-query", this.resolveRequestParams(params));
    }
}

export const authAccountHttpService = resolveService(AuthAccountHttpService);

export class AddMemberHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/member");
    }
    addMember(id) {
        return this.httpPost(id);
    }
}

export const AddMemberService = resolveService(AddMemberHttpService);
export class PersonInfoHttpService extends HttpService {
    constructor() {
        super("contact-personal/contact-personal");
    }
    saveInfo(data) {
        return this.httpPost("contact-person", data);
    }
    getInfo() {
        return this.httpGet("contact-person");
    }
    modifyInfo(id, data) {
        return this.httpPut("modify-contact-person/" + id, data);
    }
}

export const PersonInfoService = resolveService(PersonInfoHttpService);
