import { HttpService, resolveService } from "@reco-m/core";

export class CMSHttpService extends HttpService {
    headJson;

    getHeadJson() {
        const jsonURL = "seo.json";
        return this.headJson
            ? Promise.resolve(this.headJson)
            : new Promise(resolve => {
                  fetch(jsonURL)
                      .then(response => response.json())
                      .then(data => (this.headJson = resolve(data)));
              });
    }
}

export const cmsService = resolveService(CMSHttpService);
