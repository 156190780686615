import React from "react";

import { Button } from "antd";

import { PureComponent } from "@reco-m/core";

export namespace Countdown {
    export interface IProps extends PureComponent.IProps {
        start: (ctrl: Countdown.Component) => boolean | void;

        content?: string;

        className?: string;

        isButton?: boolean;
        buttonSize?: "small" | "default" | "large";
        buttonType?: "default" | "link" | "ghost" | "primary" | "dashed" | "danger";
    }

    export interface IState extends PureComponent.IState {
        delay: number;
    }

    export class Component<P extends Countdown.IProps = Countdown.IProps, S extends Countdown.IState = Countdown.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            className: "size-14 grayColor3",
        };
    
        protected interval;
    
        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { delay: 0 } as S;
        }
    
        start(): () => void {
            this.clearInterval();
    
            this.setState({ delay: 60 });
    
            this.interval = setInterval(() => {
                const { delay } = this.state;
    
                if (delay > 0) this.setState({ delay: delay - 1 });
                else this.clearInterval();
            }, 1000);
    
            return this.cancel.bind(this);
        }
    
        cancel(): void {
            this.setState({ delay: 0 });
    
            this.clearInterval();
        }
    
        protected clearInterval() {
            this.interval && clearInterval(this.interval);
        }
    
        componentWillUnmount() {
            this.clearInterval();
        }
    
        render(): React.ReactNode {
            const { delay } = this.state,
                { start, content, className, isButton, buttonSize, buttonType, disabled } = this.props as any;
    
            if (!isButton) return delay > 0 ? <span className={this.classnames(className)}>{delay} 秒</span> : <a onClick={() => start && start(this.start.bind(this))}>{content}</a>;
            else
                return (
                    <Button disabled={disabled} size={buttonSize} type={buttonType} onClick={() => (delay > 0 ? null : start && start(this.start.bind(this)))}>
                        {delay > 0 ? <span className={this.classnames(className)}>{delay} 秒</span> : <a>{content}</a>}
                    </Button>
                );
        }
    }
}


