import { removeLocalStorage, setLocalStorage } from "@reco-m/core";
import { authService } from "@reco-w/auth-service";

export async function loginSet() {
    const d = await authService.getCurrentUser(),
        u = d.currentUser;
    setLocalStorage("currentUser", JSON.stringify(u));
    setLocalStorage("UserID", u.id);
    setLocalStorage("UserName", u.realName || u.name);
    // await memberSet(u.ID);
}
export function removelogin() {
    removeLocalStorage("currentUser");
    removeLocalStorage("UserID");
    removeLocalStorage("UserName");

    // removeMember();
}

// export async function memberSet(userID = localStorage.UserID) {
//     const m = await memberService.get(userID),
//         c = (m && m.Certify && m.Certify[0]) || {};
//     if (c.IsValid) {
//         localStorage.CompanyID = c.CompanyID;
//         localStorage.CompanyName = c.CompanyName;
//         localStorage.Certify = JSON.stringify(c);
//         if (c.PermissionNames === "企业管理员") localStorage.IsAdmin = 1;
//         else localStorage.removeItem("IsAdmin");
//     } else {
//         removeMember();
//     }
//     return m;
// }
export function removeMember() {
    localStorage.removeItem("CompanyID");
    localStorage.removeItem("CompanyName");
    localStorage.removeItem("IsAdmin");
    localStorage.removeItem("Certify");
}
