import React from "react";
import { message } from "antd";
import { UploadProps } from "antd/lib/upload";

import { BaseAttach } from "@reco-m/core";

import { getDefaultProps } from "./util";
import { UploadWrap } from "./upload.wrap";

export namespace Attach {
    export interface IProps extends BaseAttach.IProps, UploadProps {
        customUpload?: boolean;
    }

    export interface IState extends BaseAttach.IState {}

    export class Component<P extends IProps = IProps, S extends IState = IState> extends BaseAttach.Component<P, S> {
        static defaultProps = getDefaultProps();

        protected onShowErrorMessage(msg: string): void {
            message.error(msg);
        }

        render(): React.ReactNode {
            return <UploadWrap.Component {...this.props} />;
        }
    }
}
