import { connect } from "dva";

import { freeze } from "immer";

import { CoreComponent } from "../container/core-component";

export function template<P extends CoreComponent.IProps, R = any, C = React.ComponentType<P>>(
    component: C,
    mapStateToProps: (state: any) => R = (state: any) => state,
    getPrimaryReducer?: (state: R) => any
): C {
    return connect((state: any) => {
        const nextState = mapStateToProps(state),
            primaryReducer = typeof getPrimaryReducer === "function" && getPrimaryReducer(nextState);

        return { state: freeze(primaryReducer ? { ...primaryReducer, ...nextState } : nextState) };
    })(component as any) as any;
}
