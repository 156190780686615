import { CoreEffects, CoreState, CoreReducers, getLocalStorage, setLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";

import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { Namespaces as commonNamespaces, EnumWorkOrderStatus } from "@reco-w/common-models";
import { tagService } from "@reco-w/tag-service";
import { TIPS } from "@reco-w/home-common";
import { freeze } from "immer";

import { parkService, searchService } from "@reco-w/home-service";
import { Namespaces } from "./common";
import { companyAndInstitutionService } from "@reco-w/bi-demand-service";
export namespace headerModel {
    export const namespace = Namespaces.header;

    export const state: any = freeze(
        {
            ...CoreState,
            dataSource: null,
            currentUser: null,
            parkNames: null,
            radioSelect: getLocalStorage("ParkID") ? getLocalStorage("ParkID") : "",
            ParkID: getLocalStorage("ParkID") ? getLocalStorage("ParkID") : "",
            ParkName: getLocalStorage("ParkName") ? getLocalStorage("ParkName") : "",
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *initPage({ error, inputData, isPreventGetUser, judgeParkCode }, { call, put }) {
            try {
                yield put({ type: "input", data: inputData });
                yield put({ type: "getPark", judgeParkCode, error });
                if (!isPreventGetUser) {
                    yield put({ type: "getCertify", error, isgetOrder: client["isgetOrderOnHeader"] });
                }
                yield put({ type: "getServiceCatalogue", error });
            } catch (e) {
                yield call(error, "Head-initPage：" + e.errmsg);
            }
        },

        *getCertify({ isRefreshUser }, { put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/getCertify`, isRefreshUser });
                const memberCommonState: any = yield select((state) => state[commonNamespaces.memberCommon]);
                const user = memberCommonState.user;
                const member = memberCommonState.member;
                const currentUser = user.currentUser;
                if (currentUser) {
                    yield put({ type: "getUserCompanyAndInstitution", data: currentUser });
                }
                yield put({ type: "input", data: { user: currentUser, member } });
            } catch (e) { }
        },

        *getPark({ callback, judgeParkCode }, { call, put }) {
            try {
                const { items } = yield call(parkService.getPaged);
                let parkNames: any[] = [];
                items &&
                    items.length > 0 &&
                    items.map((item) => {
                        parkNames.push({ label: item.parkName, value: item.id, code: item.parkCode });
                    });

                if (judgeParkCode) {
                    const selectedPark = parkNames && parkNames.length > 0 && parkNames.find((x) => x.code === judgeParkCode);
                    const isRefresh = getLocalStorage("ParkCode") && selectedPark && selectedPark.value.toString() !== getLocalStorage("ParkID");
                    if (isRefresh || !getLocalStorage("ParkID")) {
                        setLocalStorage("ParkID", selectedPark.value);
                        setLocalStorage("ParkName", selectedPark.label);
                        setLocalStorage("ParkCode", selectedPark.code);
                        isRefresh && window.location.reload();
                    }
                    yield put({ type: "input", data: { selectedPark } });
                }

                // console.log('1111111', parkNames, getLocalStorage("ParkID"));
                if (parkNames && parkNames.length > 0 && !getLocalStorage("ParkID") && !getLocalStorage("ParkName")) {
                    setLocalStorage("ParkID", parkNames[0].value);
                    setLocalStorage("ParkName", parkNames[0].label);
                    setLocalStorage("ParkCode", parkNames[0].code);
                    yield put({ type: "input", data: { radioSelect: parkNames[0].value, ParkName: parkNames[0].label, i: Math.random() } });
                }

                if (!getLocalStorage("ParkCode")) {
                    if (getLocalStorage("ParkID")) {
                        const park = parkNames.find((x) => x.value.toString() === getLocalStorage("ParkID"));
                        park && setLocalStorage("ParkCode", park.code);
                    } else setLocalStorage("ParkCode", server["defaultParkCode"]);
                }
                yield put({ type: "input", data: { parkNames } });

                if (callback) yield call(callback);
            } catch (e) {
                console.log("getParkErr", e);
            }
        },


        *getServiceCatalogue({ }, { call, put, select }) {
            try {
                const state = yield select((state) => state[Namespaces.header]);
                let catalogues = state!.catalogues;
                if (!catalogues) catalogues = yield call(tagService.getTagByTagClass, { tagClass: "CATALOGUE/fuwlx", parkId: localStorage.ParkID });
                yield put({ type: "input", data: { catalogues } });
            } catch (e) { }
        },

        *getGlobalSearch({ error, data, callback }, { call, put }) {
            try {
                if (data && data.key) {
                    yield put({ type: "input", data: { globalData: null } });
                    const globalData = yield call(searchService.getSearchData, {
                        ...data,
                        unitId: getLocalStorage("UnitID"),
                        parkId: getLocalStorage("ParkID"),
                        pageSize: 1,
                    });
                    let arr: any = [];
                    TIPS.map((tip) => {
                        if (globalData[tip.key]) {
                            if (globalData[tip.key].totalItems !== "0" && globalData[tip.key].totalItems !== 0) {
                                arr.push({
                                    type: tip.type,
                                    query: tip.name,
                                    count: globalData[tip.key].totalItems && Number(globalData[tip.key].totalItems),
                                });
                            }
                        } else {
                            return false;
                        }
                    });

                    console.log(111, globalData);

                    yield put({ type: "input", data: { globalData: arr } });

                    if (callback) yield call(callback, arr);
                } else {
                    yield put({ type: "input", data: { globalData: [] } });
                }
            } catch (e) {
                yield call(error, "Head-getGlobalSearch：" + e.errmsg);
            } finally {
                yield put({ type: "hideLoading" });
            }
        },
        // 检测进入入驻申请详情还是表单
        *getCheckInDetail({ data, userID }, { put, select }) {
            try {
                yield yield put({ type: `${commonNamespaces.workorderCommon}/getCheckInDetail`, data, userID });
                const workorderCommonState: any = yield select((state) => state[commonNamespaces.workorderCommon]),
                    checkInData = workorderCommonState.get("checkInData");

                const isMyMenuGetcheckID = checkInData && checkInData.CheckStatus !== undefined && checkInData.CheckStatus !== EnumWorkOrderStatus.Cancel;

                const isHeaderGetcheckID =
                    checkInData &&
                    checkInData.CheckStatus !== undefined &&
                    checkInData.CheckStatus !== EnumWorkOrderStatus.Cancel &&
                    checkInData.CheckStatus !== EnumWorkOrderStatus.Bounced;

                const checkOrderID = isHeaderGetcheckID ? checkInData && checkInData.CheckOrderID : null,
                    checkStateID = isHeaderGetcheckID ? checkInData && checkInData.CheckStateID : null,
                    mymenuCheckOrderID = isMyMenuGetcheckID ? checkInData && checkInData.CheckOrderID : null,
                    mymenuCheckStateID = isMyMenuGetcheckID ? checkInData && checkInData.CheckStateID : null;

                yield put({ type: "input", data: { checkInData, checkOrderID, checkStateID, mymenuCheckOrderID, mymenuCheckStateID } });
            } catch (e) { }
        },

        *logout({ error, success }, { call, put }) {
            try {
                yield yield put({ type: `${commonNamespaces.memberCommon}/clean` });
                yield yield put({ type: `${commonNamespaces.workorderCommon}/clean` });

                yield put({ type: `${userNamespaces.user}/logout`, success });

                yield call(success);
            } catch (e) {
                yield call(error, "Head-logout" + e.errmsg);
            }
        },

        *getUserCompanyAndInstitution({ data }, { call, put }) {
            try {
                const d = yield call(companyAndInstitutionService.getCompanyAndInstitution, data);
                const company = d.companyDetailVMList && d.companyDetailVMList[0],
                    institution = d.institutionDetailVMList && d.institutionDetailVMList[0];

                if (company) {
                    localStorage.companyName = company.customerName;
                    localStorage.companyId = company.companyId;
                } else {
                    localStorage.removeItem("companyName");
                    localStorage.removeItem("companyId");
                }

                if (institution) {
                    localStorage.institutionName = institution.institutionName;
                    localStorage.institutionId = institution.id;
                } else {
                    localStorage.removeItem("institutionName");
                    localStorage.removeItem("institutionId");
                }
                yield put({ type: "input", data: { customerName: company.customerName, institutionName: institution.institutionName } });
            } catch (e) { }
        },
    };
}
app.model(headerModel);
