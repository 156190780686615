import { HttpService, resolveService } from "@reco-m/core";

export class DemandHttpService extends HttpService {
    constructor() {
        super("demand/demands");
    }
    // 获取我的里面的收藏列表
    getDetail(id?: number) {
        return this.httpGet("/" + id);
    }
}

export const demandService = resolveService(DemandHttpService);

export class DemandDockHttpService extends HttpService {
    constructor() {
        super("r2035-demand/demands");
    }

    getDemandsDetail(id?: any) {
        return this.httpGet("getdemandsdetail/" + id);
    }

    getInterestedUser(id?: any) {
        return this.httpGet("getinteresteduser/" + id);
    }

    demandDock(data?: any) {
        return this.httpPost("interestedUser", data);
    }

    getInterestedList(data?: any) {
        return this.httpGet("interested-users", this.resolveRequestParams(data));
    }

    getMyApply(data?: any) {
        return this.httpGet("myapply", this.resolveRequestParams(data));
    }
    findIds(data?: any) {
        return this.httpPost("find-ids", data);
    }

    updateResult(id, result) {
        return this.httpPut("interested/" + id + "/result/" + result);
    }

    setStatus(id, status) {
        return this.httpPost(`${id}/status/${status}`);
    }

    addView(id) {
        return this.httpGet("view/" + id);
    }

    /** 获取我上次发布的需求数据 */
    getMyLastSubmit() {
        return this.httpGet("get-my-last-submit");
    }
}

export const demandDockService = resolveService(DemandDockHttpService);
