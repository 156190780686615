import React from "react";
import { router } from "dva";
import { Affix, Menu, Input, Popover, Modal, Row, Col } from "antd";
import { template, setLocalStorage, getLocalStorage } from "@reco-m/core";
import { changeUrlParams, ViewComponent, mtaH5Click, statisticsEvent } from "@reco-w/core-ui";
import { Namespaces, headerModel } from "@reco-w/layout-models";
import { removelogin } from "@reco-w/util-func";

export namespace PageHeader {
    const SubMenu = Menu.SubMenu;
    // const Search = Input.Search;

    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> {
        menuKey?: string;
        isPreventGetUser?: boolean;
    }

    export interface IState extends ViewComponent.IState, headerModel.StateType {
        form: {
            key?: string;
        };
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        get parkcode() {
            return this.props.match!.params.parkcode;
        }
        isHome = this.props.location!.pathname === `/${this.parkcode}`;

        componentDidMount() {
            this.dispatch({ type: "input", data: { menuKey: this.props.menuKey, mode: "horizontal", headerID: false } });
            this.dispatch({
                type: "initPage",
                inputData: { radioSelect: getLocalStorage("ParkID") },
                isPreventGetUser: this.props.isPreventGetUser,
                judgeParkCode: this.parkcode,
                // error: e => Modal.error({ title: "消息提醒", content: e })
                error: () => {},
            });
            $(".search-box input")
                .focus(() => {
                    $(".search-box").addClass("active");
                })
                .blur(() => {
                    $(".search-box").removeClass("active");
                });

            // 手机端
            this.menuState();
            $(window).resize(() => {
                this.menuState();
            });
        }
        changeMenu(headerID) {
            this.dispatch({ type: "input", data: { headerID: !headerID } });
        }
        menuState() {
            let wh: any = $(window).width();
            if (wh < 1200) {
                this.dispatch({ type: "input", data: { mode: "inline" } });
            } else {
                this.dispatch({ type: "input", data: { mode: "horizontal" } });
            }
        }

        componentReceiveProps(nextProps: P) {
            if (this.props.menuKey !== nextProps.menuKey) {
                this.dispatch({ type: "input", data: { menuKey: nextProps.menuKey } });
            }
        }

        // componentWillUnmount() {
        //     this.dispatch({ type: "init" })
        // }

        // 切换园区
        changePark(park: any) {
            if (park) {
                mtaH5Click(statisticsEvent.parkSwitch);
                setLocalStorage("ParkID", park.value);
                setLocalStorage("ParkName", park.label);
                setLocalStorage("ParkCode", park.code);
                setLocalStorage("selectedMenu", "/home"); // 设置被选中的菜单为home
                this.dispatch({
                    type: "input",
                    data: { radioSelect: park.value, ParkName: park.label, ParkID: park.value },
                });
                if (this.isAuth()) {
                    /*                     this.dispatch({
                                            type: "config",
                                            data: {
                                                parkName: park.label,
                                                parkID: park.value
                                            },
                                            error: e => Modal.error({ title: "消息提醒", content: e })
                                        }); */
                    this.dispatch({ type: "getCheckInDetail", error: (e) => Modal.error({ title: "消息提醒", content: e }) });
                    this.dispatch({ type: "memberCommon/cleanMemberInfo" }); // 切换园区清除原来认证信息
                    this.dispatch({ type: "memberCommon/cleanLoyalty" }); // 切换园区清除原来积分信息
                }
            }
        }

        // 渲染登录按钮
        renderLogin(): React.ReactNode {
            const { state } = this.props,
                user = state!.user;

            return this.isAuth() ? (
                <>
                    <router.Link
                        to={`/${getLocalStorage("ParkCode")}/my`}
                        onClick={() => {
                            setLocalStorage("selectedMenu", "/my");
                            setLocalStorage("mySelectedMenu", "/my");
                        }}
                    >
                        {user && user.nickName}
                    </router.Link>
                    丨
                    <router.Link
                        to={`/${getLocalStorage("ParkCode")}/login`}
                        onClick={() =>
                            this.dispatch({
                                type: `logout`,
                                success: () => this.goTo(`/${getLocalStorage("ParkCode")}/login`),
                                error: (e) => Modal.error({ title: "消息提醒", content: e }),
                            })
                        }
                    >
                        注销
                    </router.Link>
                </>
            ) : (
                <>
                    <router.Link to={`/${getLocalStorage("ParkCode")}/login`}> 登录</router.Link>丨
                    <router.Link to={`/${getLocalStorage("ParkCode")}/login/register`}>注册</router.Link>
                </>
            );
        }

        // 渲染最上栏
        renderParkAndLogin(): React.ReactNode {
            const { state } = this.props,
                parkNames = state!.parkNames || [],
                radioSelect = getLocalStorage("ParkID") || state!.radioSelect,
                ParkName = getLocalStorage("ParkName") || state!.ParkName;

            const content = (
                <div className="park-select">
                    <div className="park-select-box">
                        {parkNames &&
                            parkNames.length > 0 &&
                            parkNames.map((item, i) => (
                                <router.Link key={"park" + i} className="park-radio-box" to={`/${item.code}`} onClick={() => this.changePark(item)}>
                                    <span className={"park-radio" + (item.value === radioSelect ? " park-radio-checked" : "")}>
                                        <span className="park-radio-input"></span>
                                        <span className={"park-radio-inner" + (item.value === radioSelect ? " park-radio-inner-checked" : "")}></span>
                                    </span>
                                    <span className="park-radio-text">{item.label}</span>
                                </router.Link>
                            ))}
                    </div>
                </div>
            );

            return (
                <div className="nav">
                    <div className="container">
                        <span>
                            <i className="icon iconmarker mr10"></i>
                            当前园区：{ParkName}
                            {parkNames.length > 0 && (
                                <Popover placement="bottomLeft" content={content} title="选择园区" trigger="hover">
                                    <a>【切换】</a>
                                </Popover>
                            )}
                        </span>
                        <div className="pull-right">{this.renderLogin()}</div>
                    </div>
                </div>
            );
        }

        getSelectedKeys(menuKey) {
            if (menuKey) return [menuKey];
            const selectedMenuText = getLocalStorage("selectedMenu"),
                selectedMenus = selectedMenuText && selectedMenuText.split("?");
            let selectedMenu = selectedMenus && selectedMenus.length > 0 && selectedMenus[0];

            return [selectedMenu || ""];
        }

        logout() {
            this.dispatch({
                type: `logout`,
                success: () => {
                    removelogin();
                    this.goTo(`/${getLocalStorage("ParkCode")}/login`);
                },
                error: (e) => Modal.error({ title: "消息提醒", content: e }),
            });
            // this.dispatch({
            //     type: "user/logout",
            //     success: () => {
            //         this.goTo(`/${getLocalStorage("ParkCode")}/login`);
            //     },
            //     error: (e) => Modal.error({ title: "user/logout", content: e.errmsg }),
            // });
        }

        getUserName(user) {
            // const { state } = this.props,
            // customerName = state!.customerName,
            // institutionName = state!.institutionName;
            // console.log(customerName,institutionName);
            // if(institutionName){
            //     return <>{institutionName}</>
            // }
            // if(customerName){
            //     return <>{customerName}</>
            // }
            if (user) {
                if (user.realName) {
                    return <>{user && user.realName}</>;
                } else if (user.nickName) {
                    return <>{user && user.nickName}</>;
                } else {
                    return <>{user && user.mobile}</>;
                }
            }
            return "";
        }

        renderRight(): JSX.Element {
            const state: any = this.props.state,
                user = state!.user;

            return (
                <>
                    <div className={`user-info ${this.isHome ? "header-login-white" : ""}`}>
                        {this.isAuth() ? (
                            <div>
                                <span className="info-img" style={{ display: "none", backgroundImage: `url("../src/assets/images/activity1.jpg")`, backgroundSize: "cover" }} />
                                <router.Link
                                    className="info-name"
                                    to={`/${getLocalStorage("ParkCode")}/my/mynotice`}
                                    onClick={() => {
                                        localStorage.setItem("mySelectedMenu", "");
                                        setLocalStorage("selectedMenu", "/my");
                                        setLocalStorage("mySelectedMenu", "/my");
                                    }}
                                >
                                    欢迎您，{this.getUserName(user)}
                                </router.Link>
                                <span> | </span>
                                <a onClick={() => this.logout()}>退出</a>
                            </div>
                        ) : (
                            <>
                                <router.Link className="name margin-right" to={`/${getLocalStorage("ParkCode")}/login`}>
                                    <i className="icon iconsuo margin-right-xs" />
                                    <span>用户登录</span>
                                </router.Link>

                                <router.Link className="sign-out" to={`/${getLocalStorage("ParkCode")}/login/register`}>
                                    <i className="icon iconyonghu margin-right-xs" />
                                    <span>用户注册</span>
                                </router.Link>
                            </>
                        )}
                    </div>
                </>
            );
        }

        search(): JSX.Element {
            const { state } = this.props,
                searchKey = state!.searchKey;
            // const suffix = <i className="icon iconsearch1 hand" onClick={() => this.searchKey(searchKey)}></i>;
            return (
                <Input
                    placeholder="输入关键字查询"
                    value={searchKey}
                    suffix={<i className="icon iconsearch1 hand" onClick={() => this.searchKey(searchKey)}></i>}
                    className="search-box"
                    onPressEnter={() => this.searchKey(searchKey) as any}
                    onChange={(e) => {
                        this.dispatch({ type: "input", data: { searchKey: e.target.value } });
                    }}
                />
                //
                // <div className="header-search">
                //     <Search
                //         placeholder="输入关键字查询"
                //         value={searchKey}
                //         onSearch={(e) => this.searchKey(e)}
                //         onChange={(e) => {
                //             this.dispatch({ type: "input", data: { searchKey: e.target.value } });
                //         }}
                //         // suffix={suffix}
                //         // allowClear
                //     />
                // </div>
            );
        }

        getMenuSelectedKeys() {
            const mySelectedMenu = localStorage.getItem("mySelectedMenu");

            return mySelectedMenu ? [mySelectedMenu] : [];
        }

        // 渲染目录栏
        renderMenu(): React.ReactNode {
            const { state } = this.props,
                mode = state!.mode;
            const parkCode = getLocalStorage("ParkCode");
            return (
                <Affix offsetTop={0}>
                    <div className="nav">
                        <div className="container" style={{ maxWidth: "1300px" }}>
                            <Menu
                                onClick={(e: any) => localStorage.setItem("mySelectedMenu", e.key)}
                                selectedKeys={this.getMenuSelectedKeys()}
                                // mode="horizontal"
                                mode={mode}
                                theme="dark"
                                className="index-header"
                            >
                                <Menu.Item key="/home">
                                    <router.Link to={`/${parkCode}`}>首页</router.Link>
                                </Menu.Item>
                                <SubMenu title="服务中心">
                                    <Menu.Item key="/institution">
                                        <router.Link to={`/${parkCode}/market/all`}>服务机构</router.Link>
                                    </Menu.Item>
                                    <Menu.Item key="/product">
                                        <router.Link to={`/${parkCode}/product`}>服务产品</router.Link>
                                    </Menu.Item>
                                    <Menu.Item key="/platform">
                                        <router.Link to={`/${parkCode}/platform?lx=1`}>专业服务平台</router.Link>
                                    </Menu.Item>
                                    <Menu.Item key="/survey">
                                        <router.Link to={`/${parkCode}/survey`}>问卷调研</router.Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="/lab">
                                    <router.Link to={`/${parkCode}/resource/roommeet`}>共享实验室</router.Link>
                                </Menu.Item>
                                <Menu.Item key="/instrument">
                                    <router.Link to={`/${parkCode}/resource/positionstation`}>大型仪器</router.Link>
                                </Menu.Item>
                                {/* <Menu.Item key="/demand">
                                    <router.Link to={`/${parkCode}/demand`}>企业需求</router.Link>
                                </Menu.Item>
                                <Menu.Item key="/expert">
                                    <router.Link to={`/${parkCode}/expert`}>研究者需求</router.Link>
                                </Menu.Item> */}
                                <SubMenu title="资讯·活动">
                                    <Menu.Item key="/activity">
                                        <router.Link to={`/${parkCode}/activity`}>活动中心</router.Link>
                                    </Menu.Item>
                                    <Menu.Item key="/article/DTZX">
                                        <router.Link to={`/${parkCode}/article/DTZX`}>新闻资讯</router.Link>
                                    </Menu.Item>
                                    <Menu.Item key="/article/TZTG">
                                        <router.Link to={`/${parkCode}/article/TZTG`}>通知通告</router.Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="/policy">
                                    <router.Link to={`/${parkCode}/policy`}>产业政策</router.Link>
                                </Menu.Item>
                                <Menu.Item key="/circle">
                                    <router.Link to={`/${parkCode}/circle`}>知识社区</router.Link>
                                </Menu.Item>
                                <Menu.Item key="/document">
                                    <router.Link to={`/${parkCode}/document`}>文献检索</router.Link>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                </Affix>
            );
        }

        // 关键字搜索
        searchKey(value: string) {
            console.log(value);

            if (value && value.trim()) {
                mtaH5Click(statisticsEvent.globalSearch);
                this.goTo(changeUrlParams(`/${getLocalStorage("ParkCode")}/heardsearch`, { gk: value }));
            } else {
                if (location.pathname.indexOf("heardsearch") > -1) this.goTo(`/${getLocalStorage("ParkCode")}`);
                else window.location.reload();
            }
        }

        // 渲染搜索栏
        renderSearch(): React.ReactNode {
            const { state } = this.props,
                searchKey = state!.searchKey;
            // const suffix = <i className="icon iconsearch1 hand" onClick={() => this.searchKey(searchKey)}></i>
            return (
                <Input
                    placeholder="输入关键字查询"
                    value={searchKey}
                    suffix={<i className="icon iconsearch1 hand" onClick={() => this.searchKey(searchKey)}></i>}
                    className="search-box"
                    onPressEnter={() => this.searchKey(searchKey) as any}
                    onChange={(e) => {
                        this.dispatch({ type: "input", data: { searchKey: e.target.value } });
                    }}
                />
                // <Search
                //     placeholder="输入关键字查询"
                //     value={searchKey}
                //     suffix={suffix}
                //     className="search-box"
                //     // style={{ width: 186, marginLeft: "10px" }}
                //     onSearch={e => this.searchKey(e)}
                //     onChange={e => {
                //         this.dispatch({ type: "input", data: { searchKey: e.target.value } });
                //     }}
                // />
            );
        }

        render(): React.ReactNode {
            const { state } = this.props,
                mode = state!.mode,
                headerID = state!.headerID;
            return (
                <>
                    {mode === "inline" ? (
                        <Affix offsetTop={0} className="header-affix">
                            <div className={headerID ? "second-header open" : "second-header"}>
                                <div className="header-content">
                                    <div className="header-mobile-menu" onClick={() => this.changeMenu(headerID)}>
                                        <em />
                                        <em />
                                        <em />
                                    </div>
                                    <div className="container">
                                        <Row className="header-row" align="middle" style={{ minHeight: "63px" }}>
                                            <Col span={12}>
                                                <div className="logo">
                                                    <img src="assets/images/logo.png" />
                                                </div>
                                                <div className="home-logo">
                                                    <img src="assets/images/logo2.png" />
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <Row justify="end" align="middle" className="head-info">
                                                    <Col span={12} className="text-right">
                                                        {this.renderRight()}
                                                    </Col>
                                                    <Col span={12} className="h-none pl10">
                                                        {this.search()}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {this.renderMenu()}
                            </div>
                        </Affix>
                    ) : (
                        <div className="second-header">
                            <div className="header-content">
                                <div className="container">
                                    <Row className="header-row" align="middle" style={{ minHeight: "63px" }}>
                                        <Col span={12}>
                                            <div className="logo">
                                                <img src="assets/images/logo.png" />
                                            </div>
                                            <div className="home-logo">
                                                <img src="assets/images/logo2.png" />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <Row justify="end" align="middle" className="head-info">
                                                <Col span={12} className="text-right">
                                                    {this.renderRight()}
                                                </Col>
                                                <Col span={12} className="h-none pl10">
                                                    {this.search()}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            {this.renderMenu()}
                        </div>
                    )}
                </>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
