
export function specialShow(that, title, isMobile = false) {
    const { showspecial } = that.postData|| that.props.state || {};
    if (title) {
        if (isMobile)
            return showspecial ? title : ((title && title != "" ? title.substr(0, 7) : "") + "****");
        else
            return showspecial ? title : ((title && title != "" ? title.substr(0, 1) : "") + "******");
    } else {
        return title;
    }
}
