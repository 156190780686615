import { RequestOptionsArgs } from "./type";

import { urlEncodeParams, resolveUrl } from "./utils";
import { getCurrentToken } from "./token";

export class HttpRequest {
    readonly responseType: "arraybuffer" | "blob" | "json" | "text" = "json";

    readonly options?: RequestOptionsArgs;

    constructor(public url: string, options: RequestOptionsArgs = {}) {
        this.responseType = options.responseType || this.responseType;
        this.options = options;
    }

    get request() {
        const { headers, body, params } = this.options!;

        return new Request(resolveUrl(this.url, this.getUrlEncodeParams(params)), {
            ...this.options,
            body: body === undefined ? undefined : body instanceof FormData ? body : JSON.stringify(body),
            headers: this.getHeaders(headers),
        } as any);
    }

    protected getUrlEncodeParams(params) {
        return typeof params === "object" ? urlEncodeParams(params) : params;
    }

    protected getHeaders(headers: any) {
        return this.attachAuthorization({
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json; charset=utf-8",
            ...headers,
        });
    }

    protected attachAuthorization(headers: any) {
        const { allowAnonymous } = this.options!;

        if (!allowAnonymous && !headers.Authorization) {
            const authorization = getCurrentToken();

            if (authorization) {
                headers.Authorization = authorization;
            }
        }

        return headers;
    }

    clone(): HttpRequest {
        return new HttpRequest(this.url, this.options);
    }
}
