import { HttpService, isAnonymous, resolveService } from "@reco-m/core";

export class CompanyAndInstitutionHttpService extends HttpService {
    protected companyAndInstitutionObj: any = {};
    constructor() {
        super("park-helper-user/park-helper-user");
    }

    getCompanyAndInstitution(data) {
        // this.clearCompanyAndInstitution();
        if (!data.id) return Promise.resolve({});
        if (!isAnonymous()) {
            this.companyAndInstitutionObj[data.id] || (this.companyAndInstitutionObj[data.id] = this.httpGet(`user-company-and-institution-info/${data.id}/${data.mobile}`));
        }
        return this.companyAndInstitutionObj[data.id] || Promise.resolve({});
    }

    clearCompanyAndInstitution() {
        // this.companyAndInstitutionPromise = null;
    }
}

export const companyAndInstitutionService = resolveService(CompanyAndInstitutionHttpService);
