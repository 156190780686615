import { formatDateTime } from "@reco-m/core";
// import { EnumArticleCatalogue } from "@reco-w/article-models";
// import { EnumResourceType, getResourceName } from "@reco-w/order-models";
export enum SearchType {
    // ACTIVITY = 1, // 活动
    // NOTICE = 12, // 通知
    // ARTICLE = 13, // 资讯
    // MEETING = 4, // 会议室
    // WORKING = 5, // 工位
    // ADVERTISEMENT = 6, // 广告位
    // SQUARE = 7, // 场地
    // SERVICE = 8, // 服务联盟
    // POLICY = 9, // 政策
    // Circle = 10, // 话题
    // Product = 11 // 服务产品

    ServiceProduct = 1, // 服务产品
    ServiceInstitution = 2, // 服务机构
    MeetingRoom1 = 3, // (共享)实验室
    MeetingRoom2 = 4, // (大型)仪器
    Demand = 5, // 需求（大厅）
    R2035Expert = 6, // (产业)专家
    Activity = 7, // 活动(中心)
    Policy = 8, // (产业)政策
    Circle = 10, // 话题
}
export const TIPS = [
    { type: SearchType.ServiceProduct, key: "ServiceProduct", name: "服务产品", menukey: `/product` },
    { type: SearchType.ServiceInstitution, key: "ServiceInstitution", name: "服务机构", menukey: `/market/all` },
    { type: SearchType.MeetingRoom1, key: "MeetingRoom1", name: "实验室", menukey: `/resource/roommeet` },
    { type: SearchType.MeetingRoom2, key: "MeetingRoom2", name: "仪器", menukey: `/resource/positionstation` },
    { type: SearchType.Demand, key: "Demand", name: "需求", menukey: `/demand` },
    { type: SearchType.R2035Expert, key: "R2035Expert", name: "专家", menukey: `/expert` },
    { type: SearchType.Activity, key: "Activity", name: "活动", menukey: `/activity` },
    { type: SearchType.Policy, key: "Policy", name: "政策", menukey: `/policy` },

    // { type: SearchType.ACTIVITY, key: "Activity", name: "活动", menukey: `/activity` },
    // { type: SearchType.NOTICE, key: "Notice", name: "通知", menukey: `/article/${EnumArticleCatalogue.Notification}` },
    // { type: SearchType.ARTICLE, key: "News", name: "资讯", menukey: `/article/${EnumArticleCatalogue.Article}` },
    // { type: SearchType.MEETING, key: "MeetingRoom", name: "会议室", menukey: `/resource/${getResourceName(EnumResourceType.meetingRoom)}` },
    // { type: SearchType.WORKING, key: "CubicleRoom", name: "工位", menukey: `/resource/${getResourceName(EnumResourceType.cubicleRoom)}` },
    // { type: SearchType.ADVERTISEMENT, key: "AdvertisingSpace", name: "广告位", menukey: `/resource/${getResourceName(EnumResourceType.advertisingSpace)}` },
    // { type: SearchType.SQUARE, key: "Venue", name: "场地", menukey: `/resource/${getResourceName(EnumResourceType.venue)}` },
    // { type: SearchType.SERVICE, key: "ServiceInstitution", name: "服务联盟", menukey: `/market/all` },
    // { type: SearchType.POLICY, key: "Policy", name: "政策", menukey: `/policy` },
    // { type: SearchType.Circle, key: "Topic", name: "话题", menukey: `/circle` },
    // { type: SearchType.Product, key: "ServiceProduct", name: "服务产品", menukey: `/market/all` }
];
/** 排序方式枚举 */
export enum EnumSeq {
    Asc = 1,
    Desc = 2,
}

export enum EnumSeqWord {
    Asc = "",
    Desc = " desc",
}
/** End 排序方式枚举 */

export function getKeyWord(title, keyword) {
    const re = new RegExp(keyword, "g"); // 定义正则
    title = title && title.replace(re, `<span class="color-waiting">${keyword}</span>`); // 进行替换，并定义高亮的样式
    return <span dangerouslySetInnerHTML={{ __html: title }}></span>;
}

export function initTime() {
    const now = new Date(),
        h = now.getHours(),
        m = now.getMinutes();
    const nextH = h !== 24 ? h + 1 : 0;

    if (m < 30) return { startDate: formatDateTime(now, "yyyyMMddhh3000"), endDate: formatDateTime(now, "yyyyMMdd" + nextH + "0000") };
    else return { startDate: formatDateTime(now, "yyyyMMdd" + nextH + "0000"), endDate: formatDateTime(now, "yyyyMMdd" + nextH + "3000") };
}

// 处理Url，获取开始时间与结束时间参数
export function getTime(time: string) {
    const newTime = new Date(+time.substring(0, 4), +time.substring(4, 6) - 1, +time.substring(6, 8), +time.substring(8, 10), +time.substring(10, 12), +time.substring(12, 14));
    return formatDateTime(newTime);
}
