import { CoreEffects, CoreReducers, getLocalStorage, pictureService, removeLocalStorage } from "@reco-m/core";
import { routerRedux } from "dva";
import { app } from "@reco-w/core-ui";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { authService, PersonInfoService } from "@reco-w/auth-service";

import { Namespaces } from "./common";

const state: any = {};

export const userReducers: ReducersMapObject = {
    ...CoreReducers,

    init() {
        return state;
    },
};

export const userEffects: EffectsMapObject = {
    ...CoreEffects,

    *getEdit({ params }, { call, put }) {
        if (params.ID) {
            const { Account } = yield call(authService.getEdit, { id: params.ID }) || {};
            if (Account && Account.IsValid === false) {
                yield put(routerRedux.push(`/${getLocalStorage("ParkCode")}/login`));
            }
            yield put({ type: "input", data: Account });
        }
    },

    *getAvatar({}, { call, put }) {
        try {
            const user = yield call(authService.getCurrentUser);
            const currentUser = user && user!.currentUser;
            if (currentUser && currentUser.id) {
                const pictureSrc = yield call(pictureService.getPictureList, {
                    bindTableName: "sys_account",
                    bindTableId: currentUser.id,
                    customType: 1,
                });
                yield put({ type: "input", data: { avatar: pictureSrc ? pictureSrc[pictureSrc.length - 1] : "" } });
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    *getUserInfo({}, { call, put }) {
        try {
            const userInfo = yield call(PersonInfoService.getInfo);
            yield put({ type: "input", data: { userInfo } });
        } catch (error) {
            console.error("getUserInfo", error);
        }
    },

    *cleanCurrentUser({}, {}) {
        authService.clearCurrentUser();
    },

    *getCurrentUser({ error, callback }, { call, put }) {
        try {
            const user = yield call(authService.getCurrentUser);

            yield put({ type: "input", data: user });

            if (callback) yield call(callback, user);
        } catch (e) {
            error && error!(e);
        }
    },

    *refreshCurrentUser({ error, callback }, { call, put }) {
        try {
            const user = yield call(authService.refreshCurrentUser);

            yield put({ type: "input", data: user });

            if (callback) yield call(callback, user);
        } catch (e) {
            error && error!(e);
        }
    },

    *getCurrentUserFullInfo({ error, callback, ...props }, { put, call }) {
        try {
            yield put({ ...props, error, type: "getAvatar" });

            yield put({
                ...props,
                error,
                type: "getCurrentUser",
                *callback(user) {
                    yield put({ type: "getEdit", params: user });

                    callback && (yield call(callback));
                },
            });
        } catch (e) {
            error!(e);
        }
    },

    *logout({ error, success }, { call, put }) {
        try {
            yield call(authService.logout);

            yield put({ type: "init" });
            removeLocalStorage("UserID");
            localStorage.removeItem("institutionId");

            yield call(success);
        } catch (e) {
            yield call(error, e);
        }
    },
};

app.model({
    namespace: Namespaces.user,
    state,
    reducers: userReducers,
    effects: userEffects,
});
