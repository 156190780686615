import { HttpService, resolveService, getLocalStorage } from "@reco-m/core";

export class MemberHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/authentication");
    }


    // 获取员工列表
    getCompanyStaffList(data?: any) {
        return this.httpGet("page-company-staff", this.resolveRequestParams(data));
    }

    // 认证状态修改
    certifyUpdate(data?: any) {
        return this.httpPut("park-account-audit", data);
    }

    getMember(accountId, parkId) {
        return this.httpGet(accountId + "/" + parkId);
    }

    getCurrentUser() {
        return this.currentUser ? this.currentUser : this.httpGet("GetCurrentUser").then(d => (this.currentUser = d));
    }

    clearCurrentUser() {
        delete this.currentUser;
    }

    getCertify(id) {
        return this.httpGet("GetCertify/" + id);
    }

    certifyCancel(id) {
        return this.httpPut("cancel/" + id);
    }

    unbindCertify(id) {
        return this.httpPut("unbind/" + id + "/" + getLocalStorage("ParkID"));
    }

    getStaffCertify(data) {
        return this.httpGet("GetCertifyPaged", this.resolveRequestParams(data));
    }

    getCertifyDetail(id) {
        return this.httpGet(`GetCertify/${id}`);
    }

    // certifyUpdate(data) {
    //   return this.httpPut("CertifyUpdate", data);
    // }

    certify(data) {
        return this.httpPost("Certify", data);
    }

    config(data: any) {
        return this.httpPut("Config", data);
    }

    SendResetCode(mobile: string) {
        return this.httpGet("SendResetCode", this.resolveRequestParams({ mobile }));
    }

    // checkQRCodeBindUser(key) {
    //     return this.httpGet("CheckQRCodeBindUser", this.resolveRequestParams({ key }));
    // }

    checkQRCodeBindUser(data) {
        return this.httpGet("CheckQRCodeBindUser", this.resolveRequestParams(data));
    }

    getManagers(data: any) {
        return this.httpGet("GetManagers", this.resolveRequestParams(data));
    }
}

export const memberService = resolveService(MemberHttpService);

export class MemberCertifyHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("Member/Certify");
    }

    CertifyUntying(data) {
        return this.httpPut("Delete/" + data.certifyID);
    }

    CertifyChangeInfo(data) {
        return this.httpPut(`Put/${data.ID}`, data);
    }
}

export const memberCertifyService = resolveService(MemberCertifyHttpService);

export class MemberDailyCheckHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("Member/DailyCheck");
    }

    getIntegralSign() {
        return this.httpGet("IntegralSign");
    }

    getIsSign() {
        return this.httpGet("IsSign");
    }

    getSignDay(data) {
        return this.httpGet("GetSignDay", this.resolveRequestParams(data));
    }

    getContinuitySignDay() {
        return this.httpGet("ContinuitySignDay");
    }
}

export const dailyCheckService = new MemberDailyCheckHttpService();

export class MemberLoyaltyHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("Loyalty/Index");
    }

    getOperates(data) {
        return this.httpGet("GetOperates", this.resolveRequestParams(data));
    }

    getOperatesByMonth(data) {
        return this.httpGet("GetOperatesByMonth", this.resolveRequestParams(data));
    }

    getLoyal(id) {
        return this.httpGet("Get/" + id);
    }

    getConfig(data) {
        return this.httpGet("GetConfig", this.resolveRequestParams(data));
    }

    getUserLoyalty(data) {
        return this.httpGet("GetUserLoyalty", this.resolveRequestParams(data));
    }

    gerRules() {
        return this.httpGet("GetRules");
    }
}

export const memberLoyaltyService = new MemberLoyaltyHttpService();

export class MemberLoyaltyEventHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("Loyalty/Event");
    }

    getEventStatistic(data) {
        return this.httpGet("GetEventStatistic", this.resolveRequestParams(data));
    }

    getIntergralRulue(data) {
        return this.httpGet("GetEventsByCategory", this.resolveRequestParams(data));
    }
}

export const memberLoyaltyEventService = new MemberLoyaltyEventHttpService();

export class MemberCreditsHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("MobileApi/AppLoyalty");
    }

    getRegisterLogin() {
        return this.httpGet("RegisterLogin");
    }

    getBindWeChat(data) {
        return this.httpGet("BindWeChat", this.resolveRequestParams(data));
    }

    getBindQQ(data) {
        return this.httpGet("BindQQ", this.resolveRequestParams(data));
    }

    getBindWeibo(data) {
        return this.httpGet("BindWeibo", this.resolveRequestParams(data));
    }

    getThirdShare(data) {
        return this.httpGet("ThirdShare", this.resolveRequestParams(data));
    }

    getPerfectInformation(percent) {
        return this.httpGet("PerfectInformation", this.resolveRequestParams({ percent }));
    }

    isPerfectInformation() {
        return this.httpGet("IsPerfectInformation");
    }
}

export const intergralMemberService = resolveService(MemberCreditsHttpService);

export class CustomerHttpService extends HttpService {
    constructor() {
        super("customer/customer");
    }

    getCustomers(data) {
        return this.httpGet("", this.resolveRequestParams(data));
    }
}

export const customerService = resolveService(CustomerHttpService);

export class MemberCompanyHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/company");
    }

    userPage(data: any) {
        return this.httpGet("user-page", this.resolveRequestParams(data));
    }

    setUserManager(data: any) {
        return this.httpPut(`set-user-as-manager/${data.companyId}/${data.accountId}/${data.oldAccountId}`);
    }
}

export const memberCompanyHttpService = resolveService(MemberCompanyHttpService);

export class MemberCompanyUserTypeHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/company-user-type");
    }

    list() {
        return this.httpGet("list");
    }
}

export const memberCompanyUserTypeHttpService = resolveService(MemberCompanyUserTypeHttpService);

export class IntegralSetHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("integral/set");
    }

    companySet(companyId: any) {
        return this.httpGet("company-set/" + companyId);
    }

    personalSet(accountId: any) {
        return this.httpGet("personal-set/" + accountId);
    }
}

export const integralSetHttpService = resolveService(IntegralSetHttpService);

export class MemberSignHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("member/sign");
    }

    signInfo(setId) {
        return this.httpGet("sign-info/" + setId);
    }

    sign(setId) {
        return this.httpPost("sign/" + setId);
    }
}

export const memberSignHttpService = resolveService(MemberSignHttpService);

export class IntegralConfigHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("integral/config");
    }

    getConfig() {
        return this.httpGet("detail");
    }
}

export const integralConfigHttpService = resolveService(IntegralConfigHttpService);

export class IntegralEventHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("integral/event");
    }

    integralEvent(data) {
        return this.httpGet("category-integral-event", this.resolveRequestParams(data));
    }
}

export const integralEventHttpService = resolveService(IntegralEventHttpService);

export class IntegralintegralHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("integral/integral");
    }

    intergralChart(data) {
        return this.httpGet("sum", this.resolveRequestParams(data));
    }
}

export const integralintegralHttpService = resolveService(IntegralintegralHttpService);

export class IntegralOperateHttpService extends HttpService {
    currentUser: any;

    constructor() {
        super("integral/operate");
    }

    getCompanyPage(data) {
        return this.httpGet("company-page", this.resolveRequestParams(data));
    }

    getUserPage(data) {
        return this.httpGet("user-page", this.resolveRequestParams(data));
    }
}

export const integralOperateHttpService = resolveService(IntegralOperateHttpService);
