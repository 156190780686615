import { getLocalStorage, HttpService, resolveService } from "@reco-m/core";

export class MarketHttpService extends HttpService {
    constructor() {
        super("service-alliance/service-institution");
    }

    changePopularity(ID: any) {
        return this.httpGet("ChangePopularity/" + ID);
    }

    getWithProduct(data: any) {
        return this.httpGet("GetWithProduct", this.resolveRequestParams(data));
    }

    getDetail(id?: number) {
        return this.httpGet("Get/" + id);
    }

    getInstitutionOrderByUser(data) {
        return this.httpGet("GetInstitutionOrderSingle", this.resolveRequestParams(data));
    }

    //  当前用户的机构下的所有服务申请和服务产品申请
    getOrdersByInstitution(data: any) {
        return this.httpGet("GetOrdersByInstitution", this.resolveRequestParams(data));
    }

    getByParkUser(data) {
        return this.httpGet("GetSingle", this.resolveRequestParams(data));
    }
        /**
     * 获取我最新提交的服务机构
     */
    getMyInstitution() {
        return this.httpGet("my-service-institution", this.resolveRequestParams({}, { headers: { parkId: getLocalStorage("ParkID") as any } }));
    }
}

export const marketService = resolveService(MarketHttpService);

export class ServiceProductService extends HttpService {
    constructor() {
        super("service-alliance/service-product");
    }
    onService(id, isOnService) {
        return this.httpPut("on-service/" + id + "/" + isOnService);
    }
    getConfig() {
        return this.httpGet("product-service-agreement");
    }
    changePopularity(ID: any) {
        return this.httpGet("changePopularity/" + ID);
    }

    getServiceProductChargeMode(data: any) {
        return this.httpGet("GetServiceProductChargeMode", this.resolveRequestParams(data));
    }

    serviceProductValid(data: any) {
        return this.httpPost("ServiceProductValid", data);
    }

    //  获取服务协议
    getServiceProtocol() {
        return this.httpGet("GetConfig");
    }
}

export const serviceProductService = resolveService(ServiceProductService);

class ParkHelperInstitutionHttpService extends HttpService {
    constructor() {
        super("park-helper-institution/park-helper-institution");
    }

    changePopularity(ID: any) {
        return this.httpGet("ChangePopularity/" + ID);
    }

    getWithProduct(data: any) {
        return this.httpGet("GetWithProduct", this.resolveRequestParams(data));
    }

    getDetail(id?: number) {
        return this.httpGet("Get/" + id);
    }

    getInstitutionOrderByUser(data) {
        return this.httpGet("GetInstitutionOrderSingle", this.resolveRequestParams(data));
    }

    //  当前用户的机构下的所有服务申请和服务产品申请
    getOrdersByInstitution(data: any) {
        return this.httpGet("GetOrdersByInstitution", this.resolveRequestParams(data));
    }

    getByParkUser(data) {
        return this.httpGet("GetSingle", this.resolveRequestParams(data));
    }

    getInstitutionInfo(id) {
        return this.httpGet(`institution-info/${id}`);
    }
}

export const parkHelperInstitutionService = resolveService(ParkHelperInstitutionHttpService);

export class ServiceProductHotService extends HttpService {
    constructor() {
        super("article/article");
    }
    getHotList(data) {
        return this.httpGet("" , this.resolveRequestParams(data));
    }
}

export const serviceProductHotService = resolveService(ServiceProductHotService);
