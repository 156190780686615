export const statisticsEvent = {
    // 园区
    parkSwitch: { eventid: "web_parkSwitch", eventLabel: "园区切换" },

    // 登录
    selectScanLogin: { eventid: "web_selectScanLogin", eventLabel: "选择扫码登录" },
    scanLoginSuccess: { eventid: "web_scanLoginSuccess", eventLabel: "扫码登录成功" },
    selectAccountLogin: { eventid: "web_selectAccountLogin", eventLabel: "选择账号登录" },
    accountLoginSuccess: { eventid: "web_accountLoginSuccess", eventLabel: "账号登录成功" },
    rememberPassword: { eventid: "web_rememberPassword", eventLabel: "记住密码" },
    retrievePassword: { eventid: "web_retrievePassword", eventLabel: "找回密码" },

    // 注册
    register: { eventid: "web_register", eventLabel: "账号注册" },
    registerToLogin: { eventid: "web_registerToLogin", eventLabel: "注册页跳转登录页" },

    // 全局搜索
    globalSearch: { eventid: "web_globalSearch", eventLabel: "全局搜索" },

    // banner图
    switchBanner: { eventid: "web_switchBanner", eventLabel: "首页banner图切换" },

    // 我的-个人信息
    myPersonalInfoView: { eventid: "web_myPersonalInfoView", eventLabel: "查看个人信息" },
    myPersonalInfoEdit: { eventid: "web_myPersonalInfoEdit", eventLabel: "修改个人信息" },

    // 我的-我的主页
    myHomePageView: { eventid: "web_myHomePageView", eventLabel: "查看个人中心主页" },
    myHomeNoticeAllView: { eventid: "web_myHomeNoticeAllView", eventLabel: "查看全部通知" },
    myHomeNoticeInfoView: { eventid: "web_myHomeNoticeInfoView", eventLabel: "查看通知详情" },
    myHomeInfoAllView: { eventid: "web_myHomeInfoAllView", eventLabel: "查看全部消息" },
    myHomeServiceAllView: { eventid: "web_myHomeServiceAllView", eventLabel: "查看全部服务动态" },
    myHomeActivityAllView: { eventid: "web_myHomeActivityAllView", eventLabel: "查看全部我的活动" },

    // 我的-我的主页快捷功能
    myQuickPropertyRepair: { eventid: "web_myQuickPropertyRepair", eventLabel: "快捷功能-物业报修" },
    myQuickVisitorAppointment: { eventid: "web_myQuickVisitorAppointment", eventLabel: "快捷功能-访客预约" },
    myQuickPolicyConsultation: { eventid: "web_myQuickPolicyConsultation", eventLabel: "快捷功能-政策咨询" },
    myQuickConferenceBooking: { eventid: "web_myQuickConferenceBooking", eventLabel: "快捷功能-会议预订" },
    myQuickAdvertisingBooking: { eventid: "web_myQuickAdvertisingBooking", eventLabel: "快捷功能-广告预订" },
    myQuickFeedback: { eventid: "web_myQuickFeedback", eventLabel: "快捷功能-意见反馈" },

    // 我的-服务记录
    myServiceListView: { eventid: "web_myServiceListView", eventLabel: "查看服务记录列表" },
    myServiceCodeFiltering: { eventid: "web_myServiceCodeFiltering", eventLabel: "筛选服务记录类别" },
    myServiceSearch: { eventid: "web_myServiceSearch", eventLabel: "搜索服务记录" },
    myServiceDetailView: { eventid: "web_myServiceDetailView", eventLabel: "查看服务记录详情" },
    myServiceEvaluate: { eventid: "web_myServiceEvaluate", eventLabel: "评价服务" },

    // 我的-资源订单
    myResourceOrderListView: { eventid: "web_myResourceOrderListView", eventLabel: "查看订单列表" },
    myResourceOrderTypeFiltering: { eventid: "web_myResourceOrderTypeFiltering", eventLabel: "筛选资源订单" },
    myResourceOrderDetailView: { eventid: "web_myResourceOrderDetailView", eventLabel: "查看订单详情" },
    myResourceOrderRebook: { eventid: "web_myResourceOrderRebook", eventLabel: "重新预订资源" },
    myResourceOrderCancel: { eventid: "web_myResourceOrderCancel", eventLabel: "取消预订资源" },
    myResourceOrderEvaluate: { eventid: "web_myResourceOrderEvaluate", eventLabel: "评价订单资源" },

    // 我的-消息中心
    myNoticeListView: { eventid: "web_myNoticeListView", eventLabel: "查看消息列表" },
    myNoticeDetailView: { eventid: "web_myNoticeDetailView", eventLabel: "查看消息详情" },

    // 我的-我的收藏
    myFavouriteListView: { eventid: "web_myFavouriteListView", eventLabel: "查看收藏列表" },
    myFavouriteDetailView: { eventid: "web_myFavouriteDetailView", eventLabel: "查看收藏详情" },

    // 我的-我的活动
    myActivityListView: { eventid: "web_myActivityListView", eventLabel: "查看我的活动" },
    myActivityDetailView: { eventid: "web_myActivityDetailView", eventLabel: "查看我的活动详情" },
    myActivityCancel: { eventid: "web_myActivityCancel", eventLabel: "取消活动报名" },

    // 我的-我的话题
    myCircelListView: { eventid: "web_myCircelListView", eventLabel: "查看我的话题" },
    myCircelDynamicListView: { eventid: "web_myCircelDynamicListView", eventLabel: "查看我的动态" },
    myCircelCommentPraiseView: { eventid: "web_myCircelCommentPraiseView", eventLabel: "查看我的评论/赞" },
    myCircelFollowView: { eventid: "web_myCircelFollowView", eventLabel: "查看我的关注" },
    myCircelFansView: { eventid: "web_myCircelFansView", eventLabel: "查看我的粉丝" },
    myCircelQuit: { eventid: "web_myCircelQuit", eventLabel: "退出我的话题" },
    myCircelCommentPraiseFiltering: { eventid: "web_myCircelCommentPraiseFiltering", eventLabel: "筛选我的评论/赞" },
    myCircelFollowDelete: { eventid: "web_myCircelFollowDelete", eventLabel: "删除我的关注" },
    myCircelFansFollow: { eventid: "web_myCircelFansFollow", eventLabel: "关注我的粉丝" },
    myCircelFansUnFollow: { eventid: "web_myCircelFansUnFollow", eventLabel: "取消关注我的粉丝" },
    mycircleDynamicDelete: { eventid: "web_mycircleDynamicDelete", eventLabel: "删除我的动态" },

    // 我的-问卷调研
    mySurveyListView: { eventid: "web_mySurveyListView", eventLabel: "查看问卷列表" },
    mySurveyWritenView: { eventid: "web_mySurveyWritenView", eventLabel: "查看已填问卷详情" },
    mySurveySearch: { eventid: "web_mySurveySearch", eventLabel: "问卷列表搜索" },

    // 我的-我的发票
    myInvoiceListView: { eventid: "web_myInvoiceListView", eventLabel: "查看发票列表" },
    myInvoiceDetailView: { eventid: "web_myInvoiceDetailView", eventLabel: "查看发票明细" },
    myInvoiceTitleListView: { eventid: "web_myInvoiceTitleListView", eventLabel: "查看我的发票抬头" },
    myInvoiceTitleAdd: { eventid: "web_myInvoiceTitleAdd", eventLabel: "新增发票抬头" },
    myInvoiceTitleEdit: { eventid: "web_myInvoiceTitleEdit", eventLabel: "修改发票抬头" },
    myInvoiceTitleDelete: { eventid: "web_myInvoiceTitleDelete", eventLabel: "删除发票抬头" },
    myInvoiceOrderDetailView: { eventid: "web_myInvoiceOrderDetailView", eventLabel: "查看订单明细" },

    // 我的-我的优惠券
    myCouponListView: { eventid: "web_myCouponListView", eventLabel: "查看优惠券列表" },
    myCouponTransferring: { eventid: "web_myCouponTransferring", eventLabel: "转增我的优惠券" },
    myCouponUse: { eventid: "web_myCouponUse", eventLabel: "使用我的优惠券" },
    myCouponFiltering: { eventid: "web_myCouponFiltering", eventLabel: "筛选我的优惠券" },

    // 我的-积分管理
    myPersonalIntergralView: { eventid: "web_myPersonalIntergralView", eventLabel: "查看个人积分" },
    myEnterpriseIntergralView: { eventid: "web_myEnterpriseIntergralView", eventLabel: "查看企业积分" },
    myIntergralRegularView: { eventid: "web_myIntergralRegularView", eventLabel: "查看积分规则" },
    myIntergralSignIn: { eventid: "web_myIntergralSignIn", eventLabel: "今日积分签到" },
    myIntergralRecordFiltering: { eventid: "web_myIntergralRecordFiltering", eventLabel: "筛选积分记录" },
    myIntergralJump: { eventid: "web_myIntergralJump", eventLabel: "获取积分引导跳转" },

    // 我的-企业认证
    myCompanyCertifyView: { eventid: "web_myCompanyCertifyView", eventLabel: "查看企业认证详情" },
    myCompanyCertifySubmit: { eventid: "web_myCompanyCertifySubmit", eventLabel: "提交企业认证" },
    myCompanyCertifyUnbind: { eventid: "web_myCompanyCertifyUnbind", eventLabel: "解绑企业认证" },

    // 我的-入驻项目
    myCheckInDetailView: { eventid: "web_myCheckInDetailView", eventLabel: "查看入驻项目详情" },

    // 我的-账号设置
    mySettingChangeMobile: { eventid: "web_mySettingChangeMobile", eventLabel: "换绑绑定手机" },
    mySettingChangePassword: { eventid: "web_mySettingChangePassword", eventLabel: "修改账号密码" },

    // 我的-员工管理
    myStaffListView: { eventid: "web_myStaffListView", eventLabel: "员工列表查看" },
    myStaffCertifyAudit: { eventid: "web_myStaffCertifyAudit", eventLabel: "员工认证审核" },

    // 我的-服务机构
    myMarketDetailView: { eventid: "web_myMarketDetailView", eventLabel: "服务机构详情查看" },
    myMarketServiceListView: { eventid: "web_myMarketServiceListView", eventLabel: "服务受理列表查看" },
    myMarketServiceDetailView: { eventid: "web_myMarketServiceDetailView", eventLabel: "服务受理详情查看" },
    myMarketServiceAccept: { eventid: "web_myMarketServiceAccept", eventLabel: "服务申请工单受理" },
    myMarketServiceBack: { eventid: "web_myMarketServiceBack", eventLabel: "服务申请工单退回" },
    myMarketServiceFinish: { eventid: "web_myMarketServiceFinish", eventLabel: "服务申请工单完成" },
    myMarketProductAdd: { eventid: "web_myMarketProductAdd", eventLabel: "新增服务产品" },
    myMarketProductUnshelve: { eventid: "web_myMarketProductUnshelve", eventLabel: "下架服务产品" },
    myMarketProductShelve: { eventid: "web_myMarketProductShelve", eventLabel: "上架服务产品" },
    myMarketProductFiltering: { eventid: "web_myMarketProductFiltering", eventLabel: "筛选服务产品" },

    // 我的-服务平台
    myPlatFormDetailView: { eventid: "web_myPlatFormDetailView", eventLabel: "查看服务平台详情" },
    myPlatFormSubmit: { eventid: "web_myPlatFormSubmit", eventLabel: "服务平台入驻" },
    myPlatProjectubmit: { eventid: "web_myPlatProjectSubmit", eventLabel: "服务项目添加" },

    // 话题
    circleFiltering: { eventid: "web_circleFiltering", eventLabel: "话题分类筛选" },
    circleSearch: { eventid: "web_circleSearch", eventLabel: "话题搜索" },
    circleDetailView: { eventid: "web_circleDetailView", eventLabel: "查看话题详情" },
    circleLikeDetailView: { eventid: "web_circleLikeDetailView", eventLabel: "查看感兴趣话题详情" },
    circelQuit: { eventid: "web_circelQuit", eventLabel: "退出话题" },
    circleJoin: { eventid: "web_circleJoin", eventLabel: "参与话题" },
    circleDynamicAllView: { eventid: "web_circleDynamicAllView", eventLabel: "查看全部动态" },
    circleDynamicDetailView: { eventid: "web_circleDynamicDetailView", eventLabel: "查看动态详情" },
    circleDynamicDetailReply: { eventid: "web_circleDynamicDetailReply", eventLabel: "回复动态详情" },
    circleDynamicDetailDelete: { eventid: "web_circleDynamicDetailDelete", eventLabel: "删除动态详情" },
    circleDynamicDetailPraise: { eventid: "web_circleDynamicDetailPraise", eventLabel: "点赞动态详情" },
    circleDynamicDetailReport: { eventid: "web_circleDynamicDetailReport", eventLabel: "举报动态" },
    circleUserfollow: { eventid: "web_circleUserfollow", eventLabel: "用户关注" },
    circleUserUnfollow: { eventid: "web_circleUserUnfollow", eventLabel: "取消用户关注" },
    circlePostTopic: { eventid: "web_circlePostTopic", eventLabel: "话题发布动态" },

    // 政策
    policyFiltering: { eventid: "web_policyFiltering", eventLabel: "政策筛选" },
    policySearch: { eventid: "web_policySearch", eventLabel: "搜索政策" },
    policyDetailView: { eventid: "web_policyDetailView", eventLabel: "查看政策详情" },
    policyCollect: { eventid: "web_policyCollect", eventLabel: "收藏政策" },
    policyShare: { eventid: "web_policyShare", eventLabel: "分享政策" },
    policyOriginalTextView: { eventid: "web_policyOriginalTextView", eventLabel: "查看政策原文" },
    policySupportDynamicsView: { eventid: "web_policySupportDynamicsView", eventLabel: "查看政策扶持力度" },
    policyDeclareRequirmentsView: { eventid: "web_policyDeclareRequirmentsView", eventLabel: "查看政策申报条件" },
    policyDeclareProcedureView: { eventid: "web_policyDeclareProcedureView", eventLabel: "查看政策申报程序" },
    policyMatiralView: { eventid: "web_policyMatiralView", eventLabel: "查看政策提交材料" },
    policyReceiveServiceView: { eventid: "web_policyReceiveServiceView", eventLabel: "查看政策受理服务" },
    policyRecommendationView: { eventid: "web_policyRecommendationView", eventLabel: "查看推荐政策" },
    policyConsult: { eventid: "web_policyConsult", eventLabel: "政策资讯" },

    // 资讯-通知公告
    notificationDetailView: { eventid: "web_notificationDetailView", eventLabel: "查看通知公告详情" },
    notificationHotView: { eventid: "web_notificationHotView", eventLabel: "查看热门通知公告" },
    notificationCollect: { eventid: "web_notificationCollect", eventLabel: "收藏通知公告" },
    notificationShare: { eventid: "web_notificationShare", eventLabel: "分享通知公告" },
    notificationComment: { eventid: "web_notificationComment", eventLabel: "通知公告评论" },
    notificationLast: { eventid: "web_notificationLast", eventLabel: "上一篇通知公告" },
    notificationNext: { eventid: "web_notificationNext", eventLabel: "下一篇通知公告" },

    // 资讯-动态资讯
    articleDetailView: { eventid: "web_articleDetailView", eventLabel: "查看动态资讯详情" },
    articleHotView: { eventid: "web_articleHotView", eventLabel: "查看热门动态资讯" },
    articleCollect: { eventid: "web_articleCollect", eventLabel: "收藏动态资讯" },
    articleShare: { eventid: "web_articleShare", eventLabel: "分享动态资讯" },
    articleComment: { eventid: "web_articleComment", eventLabel: "动态资讯评论" },
    articleLast: { eventid: "web_articleLast", eventLabel: "上一篇动态资讯" },
    articleNext: { eventid: "web_articleNext", eventLabel: "下一篇动态资讯" },

    // 工单提交
    workorderPropertyRepairsSubmit: { eventid: "web_workorderPropertyRepairsSubmit", eventLabel: "物业报修工单提交" },
    workorderPropertyComplaintSubmit: { eventid: "web_workorderPropertyComplaintSubmit", eventLabel: "物业投诉工单提交" },
    workorderDemandSubmit: { eventid: "web_workorderDemandSubmit", eventLabel: "需求提报工单提交" },
    workorderParkingApplySubmit: { eventid: "web_workorderParkingApplySubmit", eventLabel: "停车位申请工单提交" },
    workorderServiceApplySubmit: { eventid: "web_workorderServiceApplySubmit", eventLabel: "服务申请工单提交" },
    workorderServiceProductApplySubmit: { eventid: "web_workorderServiceProductApplySubmit", eventLabel: "服务产品申请工单提交" },
    workorderBookHouseSubmit: { eventid: "web_workorderBookHouseSubmit", eventLabel: "预约看房工单提交" },
    workorderCheckInSubmit: { eventid: "web_workorderCheckInSubmit", eventLabel: "入驻申请工单提交" },
    workorderMarketEnterSubmit: { eventid: "web_workorderMarketEnterSubmit", eventLabel: "服务机构入驻工单提交" },
    workorderParkConsultSubmit: { eventid: "web_workorderParkConsultSubmit", eventLabel: "园区咨询工单提交" },
    workorderFeedbackSubmit: { eventid: "web_workorderFeedbackSubmit", eventLabel: "意见反馈工单提交" },
    workorderPolicyConsultSubmit: { eventid: "web_workorderPolicyConsultSubmit", eventLabel: "政策咨询工单提交" },
    workorderVistorAppointmentSubmit: { eventid: "web_workorderVistorAppointmentSubmit", eventLabel: "访客预约_访客工单提交" },
    workorderRespondentAppointmentSubmit: { eventid: "web_workorderRespondentAppointmentSubmit", eventLabel: "访客预约_受访者工单提交" },


    // 首页-通知通告
    homeCheckNotification: { eventid: "web_homeCheckNotification", eventLabel: "首页查看通知通告" },
    homeCheckMoreNotification: { eventid: "web_homeCheckMoreNotification", eventLabel: "首页查看更多通知公告" },

    // 首页-园区动态
    homeCheckDynamicDetails: { eventid: "web_homeCheckDynamicDetails", eventLabel: "首页查看动态详情" },
    homeCheckMoreDynamicDetails: { eventid: "web_homeCheckMoreDynamicDetails", eventLabel: "首页查看更多动态详情" },

    // 首页-精彩活动
    homeCheckActivityDetails: { eventid: "web_homeCheckActivityDetails", eventLabel: "首页查看活动详情" },
    homeCheckMoreActivityDetails: { eventid: "web_homeCheckMoreActivityDetails", eventLabel: "首页查看更多活动" },

    // 首页-入驻服务
    homeParkViewRoom: { eventid: "web_homeParkViewRoom", eventLabel: "首页预约看房" },
    homeParkCheckin: { eventid: "web_homeParkCheckin", eventLabel: "首页企业入驻申请" },
    homeMarketEnter: { eventid: "web_homeMarketEnter", eventLabel: "首页服务商加盟" },

    // 首页-基础服务
    homeMeetingReserve: { eventid: "web_homeMeetingReserve", eventLabel: "首页会议室预订" },
    homePropertyRepair: { eventid: "web_homePropertyRepair", eventLabel: "首页物业报修" },
    homeSurvey: { eventid: "web_homeSurvey", eventLabel: "首页问卷调查" },

    // 首页-政策申报通知
    homeCheckPolicyDetails: { eventid: "web_homeCheckPolicyDetails", eventLabel: "首页查看政策详情" },
    homeCheckMorePolicyDetails: { eventid: "web_homeCheckMorePolicyDetails", eventLabel: "首页查看更多政策" },

    // 首页-服务联盟
    homeItServices: { eventid: "web_homeItServices", eventLabel: "首页IT服务" },
    homeManpowerTraining: { eventid: "web_homeManpowerTraining", eventLabel: "首页人力培训" },
    homeLegalService: { eventid: "web_homeLegalService", eventLabel: "首页法律服务" },
    homeIntellectualProperty: { eventid: "web_homeIntellectualProperty", eventLabel: "首页知识产权" },
    homeBrandCreativity: { eventid: "web_homeBrandCreativity", eventLabel: "首页品牌创意" },
    homeCompanyRegistration: { eventid: "web_homeCompanyRegistration", eventLabel: "首页公司注册" },
    homeTaxationService: { eventid: "web_homeTaxationService", eventLabel: "首页财税服务" },
    homeMoreMarket: { eventid: "web_homeMoreMarket", eventLabel: "首页查看更多服务联盟" },

    // 导航栏-首页
    navigationBarHome: { eventid: "web_navigationBarHome", eventLabel: "导航栏-首页" },

    // 导航栏-活动
    navigationBarActivity: { eventid: "web_navigationBarActivity", eventLabel: "导航栏-活动" },

    // 导航栏-服务
    navigationBarApplication: { eventid: "web_navigationBarApplication", eventLabel: "导航栏-服务-园区服务-物业报修" },
    navigationBarComplaint: { eventid: "web_navigationBarComplaint", eventLabel: "导航栏-服务-园区服务-物业投诉" },
    navigationBarResearch: { eventid: "web_navigationBarResearch", eventLabel: "导航栏-服务-园区服务-问卷调研" },
    navigationBarDemand: { eventid: "web_navigationBarDemand", eventLabel: "导航栏-服务-园区服务-需求提报" },
    navigationBarApply: { eventid: "web_navigationBarApply", eventLabel: "导航栏-服务-园区服务-停车位申请" },
    navigationBarAllMarket: { eventid: "web_navigationBarAllMarket", eventLabel: "导航栏-服务-服务联盟-全部" },
    navigationBarItServices: { eventid: "web_navigationBarItServices", eventLabel: "导航栏-服务-服务联盟-IT服务" },
    navigationBarManpowerTraining: { eventid: "web_navigationBarManpowerTraining", eventLabel: "导航栏-服务-服务联盟-人力培训" },
    navigationBarLegalService: { eventid: "web_navigationBarLegalService", eventLabel: "导航栏-服务-服务联盟-法律服务" },
    navigationBarIntellectualProperty: { eventid: "web_navigationBarIntellectualProperty", eventLabel: "导航栏-服务-服务联盟-知识产权" },
    navigationBarBrandCreativity: { eventid: "web_navigationBarBrandCreativity", eventLabel: "导航栏-服务-服务联盟-品牌创意" },
    navigationBarCompanyRegistration: { eventid: "web_navigationBarCompanyRegistration", eventLabel: "导航栏-服务-服务联盟-公司注册" },
    navigationBarTaxationService: { eventid: "web_navigationBarTaxationService", eventLabel: "导航栏-服务-服务联盟-财税服务" },
    navigationBarOtherMarket: { eventid: "web_navigationBarOtherMarket", eventLabel: "导航栏-服务-服务联盟-其他" },

    // 导航栏-空间
    navigationBarMeetingroom: { eventid: "web_navigationBarMeetingroom", eventLabel: "导航栏-空间-会议室预订" },
    navigationBarAdvertisement: { eventid: "web_navigationBarAdvertisement", eventLabel: "导航栏-空间-广告位预订" },
    navigationBarWorking: { eventid: "web_navigationBarWorking", eventLabel: "导航栏-空间-工位预订" },
    navigationBarSquare: { eventid: "web_navigationBarSquare", eventLabel: "导航栏-空间-场地预订" },
    navigationBarImpression: { eventid: "web_navigationBarImpression", eventLabel: "导航栏-空间-园区印象" },
    navigationBarCheckIn: { eventid: "web_navigationBarCheckIn", eventLabel: "导航栏-空间-入驻申请" },

    // 导航栏-资讯
    navigationBarArticle: { eventid: "web_navigationBarArticle", eventLabel: "导航栏-资讯-动态资讯" },
    navigationBarNotification: { eventid: "web_navigationBarNotification", eventLabel: "导航栏-资讯-通知公告" },

    // 导航栏-政策
    navigationBarPolicy: { eventid: "web_navigationBarPolicy", eventLabel: "导航栏-政策" },

    // 导航栏-话题
    navigationBarCircle: { eventid: "web_navigationBarCircle", eventLabel: "导航栏-话题" },

    // 导航栏-我的
    navigationBarMy: { eventid: "web_navigationBarMy", eventLabel: "导航栏-我的" },

    // 底部快捷菜单
    footerAboutUs: { eventid: "web_footerAboutUs", eventLabel: "关于我们" },
    footerRetrievePassword: { eventid: "web_footerRetrievePassword", eventLabel: "找回密码" },
    footerViewFAQs: { eventid: "web_footerViewFAQs", eventLabel: "查看常见问题" },
    footerViewUserAgreement: { eventid: "web_footerViewUserAgreement", eventLabel: "查看用户协议" },
    footerFeedback: { eventid: "web_footerFeedback", eventLabel: "意见反馈" },
    footerMarketList: { eventid: "web_footerMarketList", eventLabel: "服务列表" },

    // 浮窗指引
    oneBConsultation: { eventid: "web_oneBConsultation", eventLabel: "一键咨询" },
    oneKeyComplaint: { eventid: "web_oneKeyComplaint", eventLabel: "一键投诉" },
    aPPDownloadPage: { eventid: "web_appDownloadPage", eventLabel: "APP下载页" },

    // 活动
    activitySearch: { eventid: "web_activitySearch", eventLabel: "活动搜索" },
    activitySignUpFiltering: { eventid: "web_activitySignUpFiltering", eventLabel: "只看报名中活动" },
    activityDetailView: { eventid: "web_activityDetailView", eventLabel: "查看活动详情" },
    activitySignUp: { eventid: "web_activitySignUp", eventLabel: "填写报名信息" },
    activitySignIn: { eventid: "web_activitySignIn", eventLabel: "活动签到" },
    activityCollection: { eventid: "web_activityCollection", eventLabel: "活动收藏" },
    activitySharing: { eventid: "web_activitySharing", eventLabel: "活动分享" },
    activityReview: { eventid: "web_activityReview", eventLabel: "活动评论" },

    // 园区服务-问卷调研
    surveySearch: { eventid: "web_surveySearch", eventLabel: "问卷搜索" },
    surveyView: { eventid: "web_surveyView", eventLabel: "问卷查看" },
    surveySubmit: { eventid: "web_surveySubmit", eventLabel: "问卷提交" },
    surveyHotView: { eventid: "web_surveyHotView", eventLabel: "热门问卷查看" },
    surveyHotSubmit: { eventid: "web_surveyHotSubmit", eventLabel: "热门问卷提交" },

    // 服务联盟-服务机构
    marketList: { eventid: "web_marketList", eventLabel: "查看服务机构列表" },
    marketFiltering: { eventid: "web_marketFiltering", eventLabel: "服务机构列表筛选" },
    marketApplication: { eventid: "web_marketApplication", eventLabel: "服务机构申请" },
    marketCollect: { eventid: "web_marketCollect", eventLabel: "服务机构收藏" },
    marketShare: { eventid: "web_marketShare", eventLabel: "服务机构分享" },
    marketViewMap: { eventid: "web_marketViewMap", eventLabel: "服务机构查看地图" },
    marketDetailsView: { eventid: "web_marketDetailsView", eventLabel: "服务机构详情查看" },
    marketQCheck: { eventid: "web_marketQCheck", eventLabel: "服务机构资质查看" },
    marketProductView: { eventid: "web_marketProductView", eventLabel: "服务机构产品查看" },
    marketComment: { eventid: "web_marketComment", eventLabel: "服务机构评论" },
    marketRecommendedView: { eventid: "web_marketRecommendedView", eventLabel: "推荐服务机构查看" },

    // 服务联盟-服务产品
    productList: { eventid: "web_productList", eventLabel: "查看服务产品列表" },
    productFiltering: { eventid: "web_productFiltering", eventLabel: "服务产品列表筛选" },
    productDetailView: { eventid: "web_productDetailView", eventLabel: "服务产品详情查看" },
    productApplication: { eventid: "web_productApplication", eventLabel: "服务产品申请" },
    productCollect: { eventid: "web_productCollect", eventLabel: "服务产品收藏" },
    productShare: { eventid: "web_productShare", eventLabel: "服务产品分享" },
    productComment: { eventid: "web_productComment", eventLabel: "服务产品评论" },
    productMarketView: { eventid: "web_productMarketView", eventLabel: "产品机构查看" },
    productRecommendedView: { eventid: "web_productRecommendedView", eventLabel: "推荐服务产品查看" },

    // 会议室预订
    meetingroomFiltering: { eventid: "web_meetingroomFiltering", eventLabel: "筛选会议室" },
    meetingroomDetailView: { eventid: "web_meetingroomDetailView", eventLabel: "查看会议室详情" },
    meetingroomCollect: { eventid: "web_meetingroomCollect", eventLabel: "收藏会议室" },
    meetingroomCoupon: { eventid: "web_meetingroomCoupon", eventLabel: "领取会议室优惠券" },
    meetingroomReserve: { eventid: "web_meetingroomReserve", eventLabel: "预订会议室" },
    meetingroomReserveSubmit: { eventid: "web_meetingroomReserveSubmit", eventLabel: "预订会议室提交" },
    meetingroomRecommendView: { eventid: "web_meetingroomRecommendView", eventLabel: "查看推荐会议室" },

    // 广告位预订
    advertisementFiltering: { eventid: "web_advertisementFiltering", eventLabel: "筛选广告位" },
    advertisementDetailView: { eventid: "web_advertisementDetailView", eventLabel: "查看广告位详情" },
    advertisementCollect: { eventid: "web_advertisementCollect", eventLabel: "收藏广告位" },
    advertisementCoupon: { eventid: "web_advertisementCoupon", eventLabel: "领取广告位优惠券" },
    advertisementReserve: { eventid: "web_advertisementReserve", eventLabel: "预订广告位" },
    advertisementReserveSubmit: { eventid: "web_advertisementReserveSubmit", eventLabel: "预订广告位提交" },
    advertisementRecommendView: { eventid: "web_advertisementRecommendView", eventLabel: "查看推荐广告位" },

    // 工位预订
    workingFiltering: { eventid: "web_workingFiltering", eventLabel: "筛选工位" },
    workingDetailView: { eventid: "web_workingDetailView", eventLabel: "查看工位详情" },
    workingCollect: { eventid: "web_workingCollect", eventLabel: "收藏工位" },
    workingCoupon: { eventid: "web_workingCoupon", eventLabel: "领取工位优惠券" },
    workingReserve: { eventid: "web_workingReserve", eventLabel: "预订工位" },
    workingReserveSubmit: { eventid: "web_workingReserveSubmit", eventLabel: "预订工位提交" },
    workingRecommendView: { eventid: "web_workingRecommendView", eventLabel: "查看推荐工位" },

    // 场地预订
    squareFiltering: { eventid: "web_squareFiltering", eventLabel: "筛选场地" },
    squareDetailView: { eventid: "web_squareDetailView", eventLabel: "查看场地详情" },
    squareCollect: { eventid: "web_squareCollect", eventLabel: "收藏场地" },
    squareCoupon: { eventid: "web_squareCoupon", eventLabel: "领取场地优惠券" },
    squareReserve: { eventid: "web_squareReserve", eventLabel: "预订场地" },
    squareReserveSubmit: { eventid: "web_squareReserveSubmit", eventLabel: "预订场地提交" },
    squareRecommendView: { eventid: "web_squareRecommendView", eventLabel: "查看推荐场地" },

    // 园区印象
    viewParkMap: { eventid: "web_viewParkMap", eventLabel: "查看园区地图" },
    browseOtherParks: { eventid: "web_browseOtherParks", eventLabel: "浏览其它园区" },
    bookingRoom: { eventid: "web_bookingRoom", eventLabel: "预约看房" }
};
