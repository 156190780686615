import { HttpService, resolveService } from "@reco-m/core";


// 园区管理  接口  organization/Park
export class OrganizationParkHttpService extends HttpService {
  constructor() {
    super("organization/park")
  }
}

export const organizationParkHttpService = resolveService(OrganizationParkHttpService);

// 空间-园区管理
export class SpaceParkHttpService extends HttpService {
  constructor() {
    super("space/park")
  }

}

export const spaceParkHttpService = resolveService(SpaceParkHttpService);










export function guid() {
  let counter = 0;

  return function (prefix?: string) {
    let uid = (+new Date()).toString(32),
      i = 0;

    for (; i < 5; i++) {
      uid += Math.floor(Math.random() * 65535).toString(32);
    }

    return (prefix || "wu_") + uid + (counter++).toString(32);
  };
}

// export class AttachHttpService extends HttpService {
//   guid: (prefix?: string) => string;

//   constructor(controllerName?: string, http?: Http) {
//     super(controllerName || "File/Index", http);

//     this.guid = guid();
//   }

//   getSid(): string {
//     return this.http.authProvider.getSid();
//   }

//   getRid(): string {
//     return this.http.authProvider.getRid();
//   }

//   getUrlToken(): string {
//     return this.http.authProvider.getUrlToken();
//   }

//   getToken() {
//     return this.http.authProvider.getCurrentToken();
//   }

//   getUploadUrl(key: IDParam): string {
//     return this.getRootUrl(this.transformUrl(this.resolveUrl("UploadChunk", key)));
//   }

//   getDownloadUrl(uid: string, file: any): string {
//     return this.getRootUrl(this.transformUrl(this.resolveUrl("Download", file.mark || file.ID)) + `?uploadID=${uid || ""}&${this.getUrlToken()}`);
//   }
// }

export class ParkBaseHttpService extends HttpService {
  constructor() {
    super("ParkBase/Index");
  }

  // 获取我的里面的收藏列表
  getUserFollow(data?: any) {
    return this.httpGet("GetUserFollow", this.resolveRequestParams(data));
  }

  // 获取收藏列表数量
  getFollowCount(data: any) {
    return this.httpGet("GetUserFollowCount", this.resolveRequestParams(data));
  }

  search(data?: any) {
    return this.httpGet("GetGlobalSearch", this.resolveRequestParams({ key: data }));
  }

  GetPaged(data?: any) {
    return this.httpGet("GetPaged", this.resolveRequestParams(data));
  }
}

export class ParkCateHttpService extends HttpService {
  constructor() {
    super("organization/parkcatalog");
  }

  getCateByCode(code: string) {
    return this.httpGet("GetByCode/" + code);
  }
}

export const parkCateService = resolveService(ParkCateHttpService);

// export const attachService = resolveService(AttachHttpService);

export const ImpressionService = resolveService(ParkBaseHttpService);
