import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { parkCateService } from "@reco-w/impression-service";
import { marketService } from "@reco-w/market-service";
import { workOrderService } from "@reco-w/workorder-service";
import { Namespaces } from "./common";

export namespace workorderCommonModel {
    export const namespace = Namespaces.workorderCommon;

    export const state: any = freeze({}, !0);

    export type StateType = typeof state;

    const checkInCache = new Map();
    const marketApplyCache = new Map();
    const institutionCache = new Map();

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,

        *getCheckInDetail({ data, parkID = getLocalStorage("ParkID"), userID = getLocalStorage("UserID"), isRefresh = false }, { call, put, select }) {
            try {
                let checkInData = isRefresh ? null : checkInCache.get((parkID = +parkID));

                if (!checkInData) {
                    const catalogue = yield call(parkCateService.getCateByCode, "ruzsq");

                    if (catalogue) {
                        let inputerID = userID;
                        if (!inputerID) {
                            yield yield put({ type: `${Namespaces.memberCommon}/getCurrentUser` });
                            const memberCommonState: any = yield select((state) => state[Namespaces.memberCommon]),
                                user = memberCommonState.user;
                            inputerID = user && user.ID;
                        }

                        const applyDetailData = yield call(workOrderService.getByUser, {
                            CatalogueID: catalogue.ID,
                            ParkID: getLocalStorage("ParkID"),
                            ShowHidCatalogs: true,
                            InputerID: inputerID,
                            ...data,
                        });

                        const checkDetailData = applyDetailData.Items;

                        let checkStatus = checkDetailData && checkDetailData[0] && checkDetailData[0].Order && checkDetailData[0].Order.Status;
                        let checkOrderID = checkDetailData && checkDetailData[0] && checkDetailData[0].Order && checkDetailData[0].Order.ID;
                        let checkStateID = checkOrderID ? checkDetailData[0].Order.FlowStateID : "";

                        checkInData = { CheckOrderID: checkOrderID, CheckStateID: checkStateID, CheckStatus: checkStatus };

                        checkInCache!.set(parkID, checkInData);
                    }
                }

                yield put({ type: `input`, data: { checkInData } });
            } catch (e) {
                // .Modal.error({ title: "消息提醒", content: "getCheckInDetail：" + e.errmsg });
            }
        },

        *cleanCheckIn({}, { put }) {
            checkInCache.clear();

            yield put({ type: `delete`, key: "checkInData" });
        },

        *getMarketApplyDetail({ parkID = getLocalStorage("ParkID"), companyID = getLocalStorage("CompanyID"), isRefresh = false }, { put }) {
            try {
                let marketApplyData = isRefresh ? null : marketApplyCache.get((parkID = +parkID));

                if (!marketApplyData) {
                    // let customerID = companyID;
                    if (!companyID) {
                        yield yield put({ type: `${Namespaces.memberCommon}/getCertify` });

                        // const memberCommonState: any = yield select((state) => state[Namespaces.memberCommon]),
                        //     certifyDetail = memberCommonState.certifyDetail;
                        // customerID = certifyDetail && certifyDetail.CompanyID;
                    }

                    // const catalogue = yield call(parkCateService.getCateByCode, "fwjgrz");

                    // if (customerID)
                    //     marketApplyData = yield call(marketService.getInstitutionOrderByUser, {
                    //         CatalogueID: catalogue && catalogue.ID,
                    //         ShowHidCatalogs: true,
                    //         CustomerID: customerID,
                    //         ...data
                    //     });

                    if (!marketApplyData) marketApplyData = {};

                    marketApplyCache!.set(parkID, marketApplyData);
                }

                yield put({ type: `input`, data: { marketApplyData } });
            } catch (e) {
                // .Modal.error({ title: "消息提醒", content: "getMarketApplyDetail：" + e.errmsg });
            }
        },

        *cleanMarketApplyDetail({}, { put }) {
            marketApplyCache.clear();

            yield put({ type: `delete`, key: "marketApplyData" });
        },

        /**
         * 获取我的服务机构
         */
        *getInstitution({ parkID = getLocalStorage("ParkID"), isRefresh = false }, { put, call }) {
            try {
                let institution = isRefresh ? null : institutionCache.get((parkID = +parkID));

                if (!institution) {
                    institution = yield call(marketService.getMyInstitution);
                    if (!institution) {
                        institution = {};
                    }

                    institutionCache!.set(parkID, institution);
                }
                yield put({ type: "input", data: { institution } });
            } catch (e) {
                // .Modal.error({ title: "消息提醒", content: "getInstitution：" + e.errmsg });
            }
        },

        *cleanInstitution({}, { put }) {
            institutionCache.clear();
            yield put({ type: `delete`, key: "institution" });
        },

        *clean({}, { put }) {
            yield put({ type: "cleanCheckIn" });
            yield put({ type: "cleanMarketApplyDetail" });
            yield put({ type: "cleanInstitution" });
        },
    };
}

app.model(workorderCommonModel);
