import React from "react";
import PropTypes from "prop-types";

import SwiperCore, { Navigation, Pagination, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import * as WebUploader from "webuploader";

import { PureComponent } from "../../container";
import { transformAssetsUrl } from "../../funcs";

import { AttachDataService } from "./attach.data-service";
import { AttachUploadService } from "./attach.upload-service";
import { downloadTemplate } from "@reco-m/core";

SwiperCore.use([Navigation, Pagination, Zoom]);

export namespace UploadrWrap {
    export interface IProps extends PureComponent.IProps {
        id?: string;
        customUpload?: boolean;
        wrapClassName?: string;
        uploadSuccess?: (fileIDs: any[], files?: any[], file?: any) => void;
    }

    export interface IState extends PureComponent.IState {
        files: any[];
        percentage?: number;
    }

    export abstract class Base<P extends IProps = IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static contextTypes = {
            getDataService: PropTypes.func,
            getUploadService: PropTypes.func,
        };

        attachDataService: AttachDataService;
        attachUploadService: AttachUploadService;

        protected unSubscribeFileChange: () => void;
        protected unSubscribeFileUpload: () => void;

        constructor(props: P, context: any) {
            super(props, context);

            this.initDataService(context);
            this.initUploadService(context);
        }

        initDataService(context: any) {
            const attachDataService: AttachDataService = (this.attachDataService = context.getDataService());

            this.state = { files: attachDataService.files } as any;

            this.unSubscribeFileChange = attachDataService.subscribeFileChange(this.onFileChange.bind(this));
        }

        onFileChange(files: any[]) {
            this.setState({ files });
        }

        initUploadService(context: any) {
            if (context.getUploadService) {
                const attachUploadService: AttachUploadService = (this.attachUploadService = context.getUploadService()),
                    unSubscribes = [
                        attachUploadService.subscribeFileQueued(this.onFileQueued.bind(this)),
                        attachUploadService.subscribeFileUploadProgress(this.onFileUploadProgress.bind(this)),
                        attachUploadService.subscribeFileUploadError(this.onFileUploadError.bind(this)),
                        attachUploadService.subscribeFileUploadSuccess(this.onFileUploadSuccess.bind(this)),
                        attachUploadService.subscribeFileUploadComplete(this.onFileUploadComplete.bind(this)),
                    ];

                this.unSubscribeFileUpload = () => unSubscribes.forEach((func) => func());
            }
        }

        onFileQueued(file: WebUploader.File) {
            if (!file.url && this.attachUploadService) {
                this.attachUploadService.getWebUploader().makeThumb(file, (error, src) => error || file.url || ((file.url = src), this.forceUpdate()));
            }

            file.formatSize = WebUploader.formatSize(file.size);
        }

        protected onFileUploadProgress(file: WebUploader.File, percentage: number) {
            this.setState({ percentage: (file.percentage = Math.round(percentage * 100)) });
        }

        protected onFileUploadError(file: WebUploader.File) {
            file.source.source.status = "error";
        }

        protected onFileUploadSuccess(file: WebUploader.File, data: any) {
            const { uploadSuccess } = this.props;
            file.url = transformAssetsUrl(data.url);

            file.source.source.status = "done";

            uploadSuccess && uploadSuccess(this.attachDataService.addFileIds, this.attachDataService.files, file);
        }

        protected onFileUploadComplete(file: WebUploader.File) {
            file.percentage = 0;

            this.forceUpdate();
        }

        beforeUpload() {
            return false;
        }

        onPreview(file: WebUploader.File) {
            this.attachDataService.handlePreview(file);

            this.forceUpdate();
        }

        removeFile(file: any) {
            this.attachDataService.remove(file);
            this.attachUploadService.removeFile(file);
        }

        addFile(file: any, fileNumber: number) {
            const { fileNumLimit, fileSizeLimit } = this.attachUploadService;

            if (fileNumber > fileNumLimit) {
                this.showErrorMessage("文件数量超过限制！");
                return;
            }

            if (fileSizeLimit && file.size > fileSizeLimit) {
                this.showErrorMessage("文件大小超过限制！");
                return;
            }

            const uploader = this.attachUploadService.getWebUploader();

            uploader.once("fileQueued", (info: WebUploader.File) => (((file.info = info) as any).uid = file.uid));

            uploader.addFile(file);
        }

        abstract showErrorMessage(content: string): void;

        abstract renderUpload(): React.ReactNode;

        abstract renderUploadBtn(): React.ReactNode;

        onCancel() {
            this.attachDataService.previewVisible = !1;

            this.forceUpdate();
        }

        onDownload(file: any) {
            console.log(file);
            downloadTemplate(file.url, file.fileName);
            // const a = document.createElement("a");
            // a.href = file.url;
            // a.download = file.fileName;
            // a.click();
        }

        renderModalBody(): React.ReactNode {
            return (
                <Swiper initialSlide={parseInt(this.attachDataService.previewImage?.sequence)} zoom={true} keyboard={true} navigation={true} pagination={false}>
                    {this.attachDataService.files.map((data, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div className="reco-upload-item">
                                    <img src={data.url} alt="" />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            );
        }

        abstract renderModal(): React.ReactNode;

        componentWillUnmount() {
            this.unSubscribeFileChange();

            if (this.unSubscribeFileUpload) {
                this.unSubscribeFileUpload();
            }
        }

        render(): React.ReactNode {
            const { id, wrapClassName } = this.props as any;

            return (
                <div className={this.classnames("clearfix", wrapClassName)} id={id}>
                    {this.renderUpload()}
                    {this.renderModal()}
                </div>
            );
        }
    }
}
