import { HttpService, resolveService } from "@reco-m/core";

export class PersonalHttpService extends HttpService {
    constructor() {
        super("contact-personal/contact-personal");
    }
    saveInfo(data) {
        return this.httpPost("contact-person", data);
    }
    getInfo() {
        return this.httpGet("contact-person");
    }
    modifyInfo(id, data) {
        return this.httpPut("modify-contact-person/" + id, data);
    }
}

export const personalService = resolveService(PersonalHttpService);
