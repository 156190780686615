import { message, Modal } from "antd";

/** 弹出报错提示 */
export function errTip(error) {
    message.error(error.errmsg || error.toString());
}

/**
 * 异步加载弹窗
 * @param content
 * @param asyncFn 必须返回一个Promise
 */
export function showAsyncModal(content, asyncFn) {
    const m = Modal.confirm({
        title: "操作提示",
        content,
        okText: "确认",
        cancelText: "取消",
        onOk() {
            m.update({
                okButtonProps: {
                    disabled: true,
                    loading: true
                }
            });

            return asyncFn();
        }
    });
}
