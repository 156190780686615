import { CoreEffects, CoreReducers, setLocalStorage, removeLocalStorage, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";
import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { Namespaces as userNamespaces } from "@reco-w/auth-user-models";
import { memberService, integralSetHttpService } from "@reco-w/member-service";

import { Namespaces } from "./common";

const state: any = {};

export const memberCommonReducers: ReducersMapObject = {
    ...CoreReducers,
};

const certifyCache = new Map(),
    loyaltyCache = new Map();

export const memberCommonEffects: EffectsMapObject = {
    ...CoreEffects,
    *getAvatar({ isRefresh }, { select, put }) {
        let memberState;
        if (!isRefresh) {
            memberState = yield select((state) => state[Namespaces.memberCommon]);
        }
        if (!memberState?.avatar) {
            yield yield put({ type: `${userNamespaces.user}/getAvatar` });
            const user = yield select((state) => state[userNamespaces.user]);
            yield put({ type: "input", data: { avatar: user.avatar } });
        }
    },

    *cleanAvatar({ }, { put }) {
        yield put({ type: "delete", key: "avatar" });
    },

    *getCurrentUser({ }, { select, put }) {
        // const memberState = yield select(state => state[Namespaces.memberCommon]);
        // if (!memberState.get("user")) {
        //     yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
        //     const user = yield select(state => state[userNamespaces.user].toJS());
        //     yield put({ type: "input", data: { user } });
        //     user && user.ID && setLocalStorage("UserID", user.ID)
        // }
        const memberState = yield select((state) => state[Namespaces.memberCommon]);
        if (!memberState.user) {
            yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
            const user = yield select((state) => state[userNamespaces.user]);
            const currentUser = user!.currentUser;
            yield put({ type: "input", data: { user } });
            currentUser && currentUser.id && setLocalStorage("UserID", currentUser.id);
        }
    },

    *refreshCurrentUser({ }, { select, put }) {
        yield yield put({ type: `${userNamespaces.user}/refreshCurrentUser` });
        const user = yield select((state) => state[userNamespaces.user]);
        const currentUser = user!.currentUser;
        yield put({ type: "input", data: { user } });
        currentUser && currentUser.id && setLocalStorage("UserID", currentUser.id);
    },

    *cleanCurrentUser({ }, { put }) {
        // yield put({ type: "delete", key: "user" });
        yield put({ type: "input", data: { user: null } });
        removeLocalStorage("UserID");
    },

    *getCurrentMemberInfo({ isRefreshUser }, { select, put, call }) {
        if (isRefreshUser) yield yield put({ type: `refreshCurrentUser` });
        else yield yield put({ type: `getCurrentUser` });
        const memberState = yield select((state) => state[Namespaces.memberCommon]),
            user = memberState!.user,
            currentUser = user.currentUser;

        if (user && currentUser && currentUser.id) {
            const memberState = yield select((state) => state[Namespaces.memberCommon]);

            if (!memberState.member) {
                const member = yield call(memberService.getMember, currentUser.id, getLocalStorage("ParkID"));
                // console.log('member', member);
                yield put({ type: "input", data: { member } });
            }
        }
    },

    *cleanCurrentMemberInfo({ }, { put }) {
        // yield put({ type: "delete", key: "member" });
        yield put({ type: "input", data: { member: null } });
    },

    *getCertify({ parkID = getLocalStorage("ParkID"), isRefreshUser, isGetInstitution, isInstitutionRefresh = false }, { put, select }) {
        let certifyDetail = certifyCache.get((parkID = +parkID));

        if (!certifyDetail) {
            yield yield put({ type: `getCurrentMemberInfo`, isRefreshUser });

            const memberState: any = yield select((state) => state[Namespaces.memberCommon]);
            certifyDetail = memberState.member;

            certifyCache.set(parkID, certifyDetail);
        }
        if (certifyDetail && (!getLocalStorage("CompanyID") || Number(getLocalStorage("CompanyID")) !== certifyDetail.companyId)) {
            setLocalStorage("CompanyName", certifyDetail.companyName || '');
            setLocalStorage("CompanyID", certifyDetail.companyId);
        }

        if (!certifyDetail) {
            removeLocalStorage("CompanyName");
            removeLocalStorage("CompanyID");
        }
        yield put({ type: "input", data: { certifyDetail } });

        if (isGetInstitution) {
            yield yield put({ type: `${Namespaces.workorderCommon}/getInstitution`, isRefresh: isInstitutionRefresh });
        }
    },
    *getUserLoyalty({ parkID = localStorage.ParkID }, { select, put, call }) {
        let userLoyalty = loyaltyCache.get((parkID = +parkID));
        if (!userLoyalty) {
            yield yield put({ type: "getCurrentMemberInfo" });
            const memberState: any = yield select((state) => state[Namespaces.memberCommon]),
                member = memberState.member;

            if (!!member && member.id) {
                let CompanySetID = yield call(integralSetHttpService.companySet, member.companyId);
                let PersonalSetID = yield call(integralSetHttpService.personalSet, member.accountId);

                userLoyalty = {
                    CompanySetID: CompanySetID.id,
                    PersonalSetID: PersonalSetID.id,
                };
                loyaltyCache.set(parkID, userLoyalty);
            } else {
                yield yield put({ type: `${userNamespaces.user}/getCurrentUser` });
                const user = yield select((state) => state[userNamespaces.user]),
                    currentUser = user.currentUser;
                let PersonalSetID = yield call(integralSetHttpService.personalSet, currentUser.id);
                userLoyalty = {
                    PersonalSetID: PersonalSetID.id,
                };
                loyaltyCache.set(parkID, userLoyalty);
            }
        }
        yield put({ type: "input", data: { userLoyalty } });
    },
    *cleanLoyalty({ }, { put }) {
        loyaltyCache.clear();
        yield put({ type: "delete", key: "userLoyalty" });
    },
    *cleanCertify({ }, { put }) {
        certifyCache.clear();
        removeLocalStorage("CompanyName");
        removeLocalStorage("CompanyID");
        yield put({ type: `delete`, key: "certifyDetail" });
    },

    *cleanMemberInfo({ }, { put }) {
        yield put({ type: "cleanCurrentMemberInfo" });
        yield put({ type: "cleanCertify" });
    },

    *cleanUserInfo({ }, { put }) {
        yield put({ type: "cleanAvatar" });
        yield put({ type: "cleanCurrentUser" });
    },

    *clean({ }, { put }) {
        yield put({ type: "cleanMemberInfo" });
        yield put({ type: "cleanUserInfo" });
        yield put({ type: "cleanLoyalty" });
    },
};

app.model({
    namespace: Namespaces.memberCommon,
    state,
    reducers: memberCommonReducers,
    effects: memberCommonEffects,
});
