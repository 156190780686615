import { HttpService, resolveService, getLocalStorage } from "@reco-m/core";
export class WorkOrderHttpService extends HttpService {
    constructor() {
        super("workorder/workorder");
    }

    getStatisticsByUser(data?: any) {
        return this.httpGet("statistics-by-user", this.resolveRequestParams(Object.assign({}, data, { IsStateOrder: true })));
    }

    getByUser(data?: any) {
        return this.httpGet("by-user", this.resolveRequestParams(Object.assign({}, data, { IsStateOrder: true })));
    }

    getInstitutionOrderByUser(data) {
        return this.httpGet("GetInstitutionOrderByUser", this.resolveRequestParams(data));
    }

    finish(id: number) {
        return this.httpPut(this.resolveUrl("finish", id));
    }

    refuse(data: any) {
        return this.httpPut(this.resolveUrl("withdraw"), data);
    }

    // 取消工单申请
    cancelApplay(data: any) {
        return this.httpPost("cancel/" + data.orderID, data.remark);
    }

    // 提醒管理员审核工单
    remindAdmin(data: any) {
        return this.httpPut(data.orderID + "/remind");
    }

    // 获取加载进度
    getLog(id: number) {
        return this.httpGet(id + "/logs");
    }

    getCatalogDTOS(code) {
        return this.httpGet(`?catalogueId=${code}`);
    }

    getCatalogDTO(code) {
        return this.httpGet(`catalogue?catalogueCode=${code}`);
    }

    getStatisticsByCatalogue() {
        return this.httpGet("statistics-by-catalogue");
    }

    getRepairCost(data) {
        return this.httpGet(this.resolveUrl("GetRepairCost"), this.resolveRequestParams(data));
    }
}

export class WorkOrderCateHttpService extends HttpService {
    constructor() {
        super("WorkOrder/Catalogue");
    }

    getCateByCode(code: string) {
        return this.httpGet("GetByCode/" + code);
    }
}

export class WorkOrderBackHttpService extends HttpService {
    constructor() {
        super("WorkOrder/Back");
    }

    getAllOrderBack(data) {
        return this.httpGet(this.resolveUrl("GetAll"), this.resolveRequestParams(data));
    }
}

export class MyMarketInHttpService extends HttpService {
    constructor() {
        super("service-alliance/service-institution");
    }
    getMyInstitution() {
        // console.log('123', this.resolveRequestParams({}, { headers: { parkId: localStorage.ParkID, Cookie: "22222222222" } }));
        return this.httpGet("my-service-institution", this.resolveRequestParams({}, { headers: { parkId: getLocalStorage("ParkID") as any } }));
    }
    messageVerifiCode(mobile, auditId) {
        return this.httpGet(`message-verification-code/${mobile}/${auditId}`);
    }
    validateMessageCode(mobile, auditId, data) {
        return this.httpGet(`validate-message-verification-code/${mobile}/${auditId}`, this.resolveRequestParams(data));
    }
    acceptanceMode() {
        return this.httpGet("acceptance-mode");
    }
    getDetail(id?: number) {
        return this.httpGet("Get/" + id);
    }

    changePopularity(id: any) {
        return this.httpGet("ChangePopularity/" + id);
    }

    getInstitutionOrderByUser(data) {
        return this.httpGet("GetInstitutionOrderSingle", this.resolveRequestParams(data));
    }

    //  当前用户的机构下的所有服务申请和服务产品申请
    getOrdersByInstitution(data: any) {
        return this.httpGet("GetOrdersByInstitution", this.resolveRequestParams(data));
    }

    getByParkUser(data) {
        return this.httpGet("GetSingle", this.resolveRequestParams(data));
    }
}

export class MyCheckInHttpService extends HttpService {
    constructor() {
        super("Checkin/Index");
    }

    postRequestProject(data: any) {
        return this.httpPost("PostRequestProject", data);
    }

    getMyCheckIn(data: any) {
        return this.httpGet("GetRequestProjectsByUser", this.resolveRequestParams(data));
    }

    getMyCheckInDetail(id: number) {
        return this.httpGet("GetRequestProjectInfo/" + id);
    }
}

export const workOrderService = resolveService(WorkOrderHttpService);
export const workOrderCateService = resolveService(WorkOrderCateHttpService);
export const workOrderBackService = resolveService(WorkOrderBackHttpService);
export const myMarketInHttpService = resolveService(MyMarketInHttpService);
export const myCheckInHttpService = resolveService(MyCheckInHttpService);
