import { HttpService, resolveService } from "@reco-m/core";

export class ParkHttpService extends HttpService {
    parks: any;

    constructor() {
        super("organization/park");
    }

    // getPaged(data: any) {
    //     return this.parks ? Promise.resolve(this.parks) : this.httpGet("GetPaged", this.resolveRequestParams(data)).then(d => (this.parks = d));
    // }
    list(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export class GlobalHttpService extends HttpService {
    constructor() {
        super("Global/Index");
    }

    getGlobalSearch(data) {
        return this.httpGet("GetGlobalSearch", this.resolveRequestParams(data));
    }

    getUserFollowCount(data) {
        return this.httpGet("GetUserFollowCount", this.resolveRequestParams(data));
    }

    getUserFollow(data) {
        return this.httpGet("GetUserFollow", this.resolveRequestParams(data));
    }
}

export const globalService = resolveService(GlobalHttpService);

export const parkService = resolveService(ParkHttpService);

export class SearchTopicHttpService extends HttpService {
    constructor() {
        super("universalsearch");
    }
    getSearchData(data: any) {
        return this.httpGet("search", this.resolveRequestParams(data));
    }
}

export const searchService = resolveService(SearchTopicHttpService);

export class ProductHttpService extends HttpService {
    constructor() {
        super("r2035-service-alliance/service-product");
    }

    catalog() {

        return this.httpGet('catalog')
    }
    addView(id) {
        return this.httpGet('view/' + id);
    }

    delete(id) {
        return this.httpDelete('/' + id);
    }

}

export const productService = resolveService(ProductHttpService);


export class InstitutionHttpService extends HttpService {
    constructor() {
        super("r2035-service-alliance/service-institution");
    }

    catalog() {

        return this.httpGet('catalog')
    }
    addView(id) {
        return this.httpGet('view/' + id);
    }

    getMyServiceInstitution() {
        return this.httpGet('my-service-institution')
    }
}

export const institutionService = resolveService(InstitutionHttpService);
