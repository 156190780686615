import React from "react";
import { Upload, message, Modal, Button } from "antd";
import { UploadProps } from "antd/lib/upload";
import { PlusCircleOutlined } from "@ant-design/icons";

import { UploadrWrap } from "@reco-m/core";

export namespace UploadWrap {
    export interface IProps extends UploadrWrap.IProps, UploadProps {
        id?: string;
        customUpload?: boolean;
    }

    export interface IState extends UploadrWrap.IState {
        files: any[];
        percentage?: number;
    }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends UploadrWrap.Base<P, S> {
        showErrorMessage(content: string): void {
            message.error(content, 3);
        }

        onChange(upload: { file: any; fileList: any[] }) {
            if (this.state.files.length > upload.fileList.length) {
                this.removeFile(upload.file);
            } else {
                this.addFile(upload.file, upload.fileList.length);
            }
        }

        getShowUploadList() {
            const { readonly } = this.props as any;

            return {
                showPreviewIcon: true,
                showRemoveIcon: !readonly,
                showDownloadIcon: true,
            };
        }

        renderUpload(): React.ReactNode {
            const { className, multiple, children, readonly, ...props } = this.props as any;

            return (
                <Upload
                    {...props}
                    className={this.classnames(className, this.getClassSet(), readonly ? "readonly" : "")}
                    beforeUpload={this.beforeUpload.bind(this)}
                    fileList={this.state.files}
                    multiple={multiple}
                    onPreview={this.onPreview.bind(this)}
                    onChange={this.onChange.bind(this)}
                    onDownload={this.onDownload.bind(this)}
                    disabled={readonly}
                    showUploadList={this.getShowUploadList()}
                >
                    {this.renderUploadBtn()}
                    {children}
                </Upload>
            );
        }

        renderUploadBtn(): React.ReactNode {
            const { customUpload, readonly } = this.props as any;

            return customUpload || readonly ? null : (
                <Button>
                    <PlusCircleOutlined />
                    上传
                </Button>
            );
        }

        renderModal() {
            return (
                <Modal
                    title={
                        <div>
                            预览 <span className="size-12 text-error">(温馨提示：双击可放大，拖动滚动条预览大图)</span>
                        </div>
                    }
                    wrapClassName="upload-modal"
                    footer={null}
                    width={"100%"}
                    visible={this.attachDataService.previewVisible}
                    destroyOnClose={true}
                    onCancel={() => this.onCancel()}
                >
                    {this.renderModalBody()}
                </Modal>
            );
        }
    }
}
