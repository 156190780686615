import { router } from "dva";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";

import { getLocalStorage } from "@reco-m/core";
import { loadLazyModule } from "@reco-w/core-ui";

function getParkCode() {
    return getLocalStorage("ParkCode") || client["parkCode"];
}

function RouterConfig({ history }: any) {
    return (
        <ConfigProvider locale={zhCN}>
            <router.Router history={history}>
                <router.Switch>
                    <router.Route path="/:parkcode/login" component={loadLazyModule(() => import(/* webpackChunkName: "login" */ "@reco-w/login"))} />
                    <router.Route exact path="/:parkcode" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/home"))} />
                    <router.Route path="/:parkcode/activity" component={loadLazyModule(() => import(/* webpackChunkName: "activity" */ "@reco-w/activity"))} />
                    <router.Route path="/:parkcode/circle" component={loadLazyModule(() => import(/* webpackChunkName: "circle" */ "@reco-w/circle"))} />
                    <router.Route path="/:parkcode/article" component={loadLazyModule(() => import(/* webpackChunkName: "article" */ "@reco-w/article"))} />
                    <router.Route path="/:parkcode/my" component={loadLazyModule(() => import(/* webpackChunkName: "information" */ "@reco-w/bi-my"))} />
                    <router.Route path="/:parkcode/policy" component={loadLazyModule(() => import(/* webpackChunkName: "policy" */ "@reco-w/policy"))} />
                    <router.Route path="/:parkcode/resource/:roomname" component={loadLazyModule(() => import("@reco-w/order-room"))} />
                    <router.Route path="/:parkcode/survey" component={loadLazyModule(() => import(/* webpackChunkName: "survey" */ "@reco-w/survey"))} />
                    <router.Route path="/:parkcode/impression" component={loadLazyModule(() => import(/* webpackChunkName: "impression" */ "@reco-w/impression"))} />
                    <router.Route path="/:parkcode/problem" component={loadLazyModule(() => import(/* webpackChunkName: "problem" */ "@reco-w/problem"))} />
                    <router.Route path="/:parkcode/workorder" component={loadLazyModule(() => import(/* webpackChunkName: "notice" */ "@reco-w/workorder"))} />
                    <router.Route path="/:parkcode/market" component={loadLazyModule(() => import(/* webpackChunkName: "market" */ "@reco-w/market"))} />
                    <router.Route path="/:parkcode/product" component={loadLazyModule(() => import(/* webpackChunkName: "market" */ "@reco-w/market-product"))} />
                    <router.Route path="/:parkcode/heardsearch" component={loadLazyModule(() => import("@reco-w/header-index"))} />
                    <router.Route path="/:parkcode/demand" component={loadLazyModule(() => import(/* webpackChunkName: "demand" */ "@reco-w/bi-demand"))} />
                    <router.Route path="/:parkcode/expert" component={loadLazyModule(() => import(/* webpackChunkName: "expert" */ "@reco-w/bi-expert"))} />
                    <router.Route path="/:parkcode/severs" component={loadLazyModule(() => import(/* webpackChunkName: "severs" */ "@reco-w/severs"))} />
                    <router.Route path="/:parkcode/sproduct" component={loadLazyModule(() => import(/* webpackChunkName: "sproduct" */ "@reco-w/sproduct"))} />
                    <router.Route path="/:parkcode/document" component={loadLazyModule(() => import("@reco-w/bi-document"))} />
                    <router.Route path="/:parkcode/platform" component={loadLazyModule(() => import("@reco-w/market-platform"))} />                    
                    <router.Redirect exact to={`/${getParkCode()}`} />
                </router.Switch>
            </router.Router>
        </ConfigProvider>
    );
}

export default RouterConfig;
