// import { getCurrentToken } from "@reco-m/core";

function downloadFile(downloadName, response) {
    if (!downloadName && (downloadName = response.headers.get("Content-Disposition")) && (downloadName = downloadName.match(/(filename)=(.*);\s?(?:\1\*=UTF-8''(.*))?$/i))) {
        downloadName = decodeURIComponent(downloadName[3] || downloadName[2]);
    }
    downloadName = downloadName || "文件下载";

    const navigator: any = window.navigator;
    if ("msSaveOrOpenBlob" in navigator) {
        navigator.msSaveOrOpenBlob(response.body, downloadName);
    } else {
        response.blob().then((blob) => {
            const a = document.createElement("a"),
                url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = downloadName;
            a.type = response.headers.get("Content-Type");
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }
}
function downloadFileError(e: any) {
    return new Promise((_, reject) => {
        const { error } = e;
        if (error instanceof Blob) {
            const fileReader = new FileReader();
            fileReader.onload = (e: any) => {
                const result = e.target.result;
                reject(result ? JSON.parse(result) : e);
            };
            fileReader.readAsText(error);
        } else {
            reject(e);
        }
    });
}

export function downloadTemplate(url, downloadName) {
    // const token: any = getCurrentToken();
    return fetch(url, {
        method: "Get",
        headers: new Headers({
            "Content-Type": "application/octet-stream;charset=utf-8",
            // authorization: token,
            responseType: "blob",
        }),
    })
        .then((response) => {
            downloadFile(downloadName, response);
        })
        .catch((e) => {
            downloadFileError(e);
        });
}
